import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabPeriPodL3 from "../../Assets/Bluelab PeriPod L3.webp";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "../../App.css";
const Bluelab_PeriPod_L3 = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 13,
      name: "Bluelab PeriPod L3",
      price: 211680,
      image: BluelabPeriPodL3,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabPeriPodL3}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab PeriPod L3</h2>
            <p className="product-price">₹{211680}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>
                Effortlessly Automate pH and Nutrient Levels in Your Growing
                Reservoirs
              </h5>
              Confidently manage your nutrient and pH levels with our advanced
              dosing system designed for efficiency and accuracy.
              <ul className="mt-3">
                <li>
                  <span className="fw-bold">Precision Dosing:</span> Utilize
                  peristaltic pumps for consistent and accurate delivery of
                  nutrient and pH solutions into your reservoirs.
                </li>
                <li>
                  <span className="fw-bold">Convenient Setup:</span> Position
                  the doser lower than the injection point for easy access and
                  installation.
                </li>
                <li>
                  <span className="fw-bold">Whisper-Quiet Operation:</span>{" "}
                  Enjoy a fan-cooled design that ensures optimal performance
                  with minimal noise.
                </li>
                <li>
                  <span className="fw-bold">Manual Dosing Option:</span> Prime
                  the tubing easily with manual dosing capabilities directly
                  from the unit.
                </li>
                <li>
                  <span className="fw-bold">High Capacity:</span> Features a
                  pump dose rate of 1200 ml/min, ensuring rapid and efficient
                  nutrient delivery.
                </li>
                <li>
                  <span className="fw-bold">Extended Tubing:</span> Comes with 4
                  meters (13 feet) of tubing for dosing pH adjustment solutions,
                  made from high-strength acid- and alkaline-resistant
                  materials.
                </li>
                <li>
                  <span className="fw-bold">Food-Grade Safety:</span> Includes
                  two lengths of 4 meters (13 feet) of food-grade tubing for
                  dosing nutrient stock solutions.
                </li>
                <li>
                  <span className="fw-bold">Algae Prevention:</span> Opaque
                  nutrient tubing is designed to prevent algae growth, ensuring
                  a healthier environment.
                </li>
                <li>
                  <span className="fw-bold">Replaceable Components:</span>{" "}
                  Easily swap out peristaltic pumps and tubing for maintenance.
                </li>
                <li>
                  <span className="fw-bold">Durable Design:</span> The rugged
                  wall-mounted structure provides long-lasting efficiency for
                  your setup.
                </li>
                <li>
                  <span className="fw-bold">Expandable System:</span> Connect
                  PeriPods to create a scalable multi-part nutrient dosing
                  system with up to 12 pumps*.
                </li>
              </ul>
              <span style={{ fontStyle: "italic" }}>*Note: Only M series
              (M3 and M4) can be linked together. L3 units can only chain with
              other L3 units.</span>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_PeriPod_L3;
