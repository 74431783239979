import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabExternalLockout from "../../Assets/Bluelab External Lockout and Alarm Box.webp";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_External_Lockout_and_Alarm_Box = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 8,
      name: "Bluelab External Lockout and Alarm Box",
      price: 5628,
      image: BluelabExternalLockout,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabExternalLockout}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Bluelab External Lockout and Alarm Box
            </h2>
            <p className="product-price">₹{5628}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>
                Enhance Your Bluelab Pro Controller with External Switches for
                Complete Dosing Control
              </h5>
              Connecting external switches to your Bluelab Pro Controller
              provides a comprehensive and customizable dosing solution. By
              integrating external lockout sensors, such as float switches, you
              can stop all dosing to prioritize plant safety. The Pro Controller
              can also be connected to an irrigation system, pausing dosing
              during feed-out cycles.
              <ul className="mt-2">
                <li>
                  Use external switches to lockout or inhibit the Pro
                  Controller’s dosing functionality.
                </li>
                <li>
                  Integrate with 'normally open' and 'normally closed' switches
                  for flexible control.
                </li>
                <li>
                  Install an external alarm, such as a horn or flashing light,
                  for an audible alert system.
                </li>
                <li>
                  Easily wire in these switch options yourself for complete
                  system customization.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_External_Lockout_and_Alarm_Box;
