import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Enquiry from "../../Components/Enquiry";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.webp";
import CardImg2 from "../../Assets/step2.webp";
import CardImg3 from "../../Assets/step3.webp";
import CardImg4 from "../../Assets/step4.webp";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import Climatec from "../../Assets/climatec.webp";
import NvphFanpad from "../../Assets/NvphFanpad.webp";
import NvphFanpad1 from "../../Assets/NvphFanpad1.webp";
import SecondaryAutomation from "../../Assets/SecondaryAutomation.webp";
import "../../App.css";

import NvphVents from "../../Assets/NvphVents.webp";
import NvphStructure2 from "../../Assets/NvphStructure2.webp";
import NvphFoundation from "../../Assets/NvphFoundation.webp";
import NvphAluminet from "../../Assets/NvphAluminet.webp";
import NvphInsectNet from "../../Assets/NvphInsectNet.webp";
import NvphShadenet from "../../Assets/NvphShadenet.webp";
import NvphFanpad2 from "../../Assets/NvphFanpad2.webp";
import CoverFanpad from "../../Assets/CoverFanpad.webp";
import NvphFanpad3 from "../../Assets/NvphFanpad3.webp";
import CoolingFanpad from "../../Assets/CoolingFanpad.webp";
import ProjectLayout from "../../Assets/ProjectLayout.webp";
import Nvph2 from "../../Assets/Nvph2.webp";

function ClimateControlled() {
  return (
    <>
      <Container fluid>
        <Row className="headingimgnew2">
          <Col className="m-5">
            <h1 className="text-dark m-4 mx-4 mt-5 px-2 fw-bold text-center">
              Climate Controlled
            </h1>
            <div className="text-dark m-4 fw-bold text-center">
              <a href="/" className=" text-dark m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-dark text-decoration-none m-3">
                Climate Controlled
              </a>
            </div>
          </Col>
        </Row>

        <Row className="m-5 ps-5">
          <h1>DESIGN</h1>
          <p className="display-6 fst-italic">Future Of Polyhouse Design</p>
          <p className="fw-bold display-1 text-warning">
            The Designs made for India
          </p>
        </Row>
        <Row className="justify-content-center p-1">
          <Col lg={8} md={10} sm={12} className="p-0">
            <img
              src={NvphFanpad2}
              alt="Climate Controlled Polyhouse, We Are Largest Project Developer In Hydroponic Farming And Soil-Less Farming.
Get in-depth feasibility analysis that covers all technical and financial feasibility indicators of the project."
              className="img-fluid rounded"
              style={{ width: "100%", height: "auto" }} // Ensure it fills the column
            />
          </Col>
        </Row>

        {/* Second Row: Two Images */}
        <Row className="justify-content-center p-1">
          <Col lg={4} md={6} sm={12} className="p-1">
            <img
              src={NvphFanpad}
              alt="Temperature-Controlled Agriculture"
              className="img-fluid rounded"
              style={{ width: "100%", height: "auto" }} // Ensure it fills the column
            />
          </Col>
          <Col lg={4} md={6} sm={12} className="p-1">
            <img
              src={NvphFanpad3}
              alt="Polyhouse Cooling Technology"
              className="img-fluid rounded"
              style={{ width: "100%", height: "auto" }} // Ensure it fills the column
            />
          </Col>
        </Row>

        {/* Third Row: Text and Image Side by Side */}
        <Row className="justify-content-center">
          <Col lg={8} className="m-5 mb-2">
            <h6 className="text-green m-2">Project Layout</h6>
            <h2 className="m-2">Project Layout</h2>

            <Row className="align-items-center">
              <Col lg={4} md={4} sm={12} className="p-0">
                <p className="text-muted m-2 text-justify">
                  At Royal Green House, we prioritize precision and clarity in
                  every project. To ensure that our polyhouse designs meet your
                  specific needs, we begin by visiting your site for a
                  comprehensive assessment. This hands-on approach allows us to
                  gather essential details, which we then translate into a
                  detailed AutoCAD design. This layout provides a clear and
                  accurate representation of the proposed polyhouse structure,
                  ensuring that all aspects are well-planned and tailored to the
                  unique conditions of your site.
                </p>
              </Col>
              <Col
                lg={8}
                md={8}
                sm={12}
                className="p-3 d-flex justify-content-center"
              >
                <img
                  src={ProjectLayout}
                  alt="High-Tech Greenhouse"
                  className="img-fluid rounded"
                  style={{ width: "100%", height: "auto" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center p-5">
          {/* This row centers the content */}
          <Col lg={10} md={10} sm={12}>
            <Row className="text-left">
              <Col>
                <p className="fw-bold display-5">Air Circulation Fans</p>
                <p className="text-justify">
                  ACF fans in a polyhouse improve air circulation,ensuring even
                  temperature, humidity,and CO2 distribution, reducing
                  condensation, and promoting healthier, stronger plant growth.
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                lg={10}
                md={10}
                sm={10}
                className="d-flex flex-column justify-content-center align-items-center pt-2"
              >
                <img
                  src={NvphVents}
                  alt="Automated Ventilation System, Fan and Pad Ventilation"
                  className="img-fluid rounded"
                  style={{ width: "1500px", height: "auto" }}
                />
              </Col>
            </Row>

            <Row className="pt-5">
              <Col>
                <div className="text-dark p-3 mt-3">
                  <h4 className="fw-bold">STRUCTURE</h4>
                  <h3 className="fw-bold pt-4 pb-2">
                    Upto 6 meter Tall Structure
                  </h3>
                  <p className="text-justify">
                    At Royal Green House Construction Pvt Ltd (RGCPL), we adhere
                    to a bay size of 8m x 4m, with the NVPH width being 35% of
                    the desired length. This design ensures ample sunlight,
                    airflow, and humidity control. We maintain a ridge height of
                    4.5 to 6 meters and place the gutter at 3.5 to 4.5 meters.
                    Our structural design is robust enough to withstand wind
                    speeds of at least 120 km/hr and a minimum load of 25kg/m².
                  </p>
                </div>
              </Col>
              <Col>
                <img
                  src={NvphStructure2}
                  alt="Galvanized Iron Frame, High-Tensile Polyhouse"
                  className="img-fluid rounded mt-3"
                  style={{ width: "600px", height: "auto" }}
                />
              </Col>
            </Row>

            <Row className="pt-5">
              <Col
                md={6}
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={NvphFoundation}
                  alt="Stronger Foundation"
                  className="img-fluid rounded"
                  style={{ width: "100%", maxWidth: "800px", height: "auto" }}
                />
              </Col>
              <Col md={6}>
                <h4 className="pt-3 fw-bold">Stronger Foundation</h4>
                <p className="text-justify">
                  At Royal Green House Construction Pvt Ltd (RGCPL), we ensure
                  strong and stable polyhouse foundations by using GI pipes with
                  a minimum outer diameter of 60 mm or more. The foundation
                  depth is at least 75 cm, adjusted based on soil type and
                  prevailing wind conditions. These pipes are grouted with a
                  cement concrete mixture of 1:2:4, utilizing telescopic
                  insertion of the column. This approach guarantees a durable
                  foundation, crucial for the long-term stability of the
                  polyhouse structure.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="m-5">
          <Col md={7} className="pe-5">
            <img
              src={CoolingFanpad}
              alt="Cooling NVPH Fanpad "
              style={{ width: "100%", height: "auto" }}
              className="img-fluid rounded"
            />
          </Col>
          <Col md={4} className="pt-5">
            <h3 className="fw-bold animationForText">COOLING SOLUTION</h3>
            <h2 className="pt-4 text-warning">RGCPL Air Circulation Fan</h2>
            <p className="pt-2">
              At the speed of 935 RPM/min, the air flow reaches 22m of distance
              breaking the humidity pockets at ease in your polyhouse.
            </p>
          </Col>
        </Row>

        <Row className="px-5">
          <Col md={5} className="pt-3">
            <h3 className="fw-bold animationForText">COVERING SOLUTION</h3>

            <h2 className="pt-4 text-warning">
              Ginegar/Suncool/Essen/Greenprobe Polyfilm
            </h2>
            <p className="pt-2 text-justify">
              At Royal Green House Construction Pvt Ltd (RGCPL), we use Polyfim
              with advanced properties that offer multiple benefits:
              <ul>
                <li>
                  Thermal effect (IR) enhances temperature control by reducing
                  heat loss, keeping the interior warmer during cooler
                  conditions.
                </li>{" "}
                <li>
                  Anti mist prevents the formation of condensation droplets,
                  ensuring clear visibility and consistent light transmission.
                </li>
                <li>
                  Anti dust reduces the accumulation of dust on the surface,
                  maintaining the film's transparency and effectiveness over
                  time.
                </li>{" "}
                <li>
                  Light diffusion ensures even distribution of light, minimizing
                  shadows and promoting uniform plant growth.
                </li>{" "}
                <li>
                  Anti virus effect provides an additional layer of protection
                  against viruses, contributing to healthier crop production.
                </li>
              </ul>
            </p>
          </Col>

          <Col md={7}>
            <img
              src={CoverFanpad}
              alt="climate controlled polyhouse"
              style={{ width: "1000px", height: "auto" }}
              className="mx-5 img-fluid rounded"
            />
          </Col>
        </Row>

        <div className="m-5">
          <Row className="text-center mb-4">
            <Col>
              <h3 className="fw-bold animationForText">SHADING SOLUTION</h3>
            </Col>
          </Row>
          <Row className="g-3">
            <Col>
              <div className="text-center">
                <img
                  src={NvphAluminet}
                  alt="Nvph"
                  className="img-fluid rounded"
                />
                <h4 className="mt-0 mb-0">RGCPL Aluminet</h4>
              </div>
            </Col>
            <Col>
              <div className="text-center">
                <img
                  src={NvphShadenet}
                  alt="Shadenet"
                  className="img-fluid rounded"
                />
                <h4 className="mt-0 mb-0">RGCPL Shadenet</h4>
              </div>
            </Col>
            <Col>
              <div className="text-center">
                <img
                  src={NvphInsectNet}
                  alt="Insect net"
                  className="img-fluid rounded"
                />
                <h4 className="mt-0 mb-0">RGCPL Insect Net</h4>
              </div>
            </Col>
          </Row>
        </div>

        <div>
          <Row className="text-center mb-4 mt-5 ">
            <Col>
              <h3 className="text-shadow fw-bold animationForText">
                MOTORIZE SYSTEM
              </h3>
            </Col>
          </Row>
          <Row className="g-3 m-5 justify-content-center ">
            <Col lg={6}>
              <div className="text-center">
                <img
                  src={SecondaryAutomation}
                  alt="automation in farming"
                  className="img-fluid rounded"
                />
                <h4 className="mt-0 mb-0">Secondary Layer Automation</h4>
              </div>
            </Col>
          </Row>
        </div>

        <Row>
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and A frame system now we can
                      grow 70 percent more food in the same area.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow anywhere you want
                    </Card.Title>
                    <Card.Text>
                      We can now grow our food anywhere with help of just water
                      and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-hand-holding-droplet text-green text-center fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title className="text-center">Less water</Card.Title>
                    <Card.Text>
                      Now grow crops on 90% less water compared to field crops.
                      This farming is possible at any location even in water
                      scared zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-bolt text-green text-center fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High value cash crops
                    </Card.Title>
                    <Card.Text>
                      Not only our daily crops but we can grow super-crops or
                      very high value cash crops with controlled environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          {/* second cards */}
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1} alt="Step One" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className=" text-white p-1">
                  We help you identify a site location to set up our hydroponic
                  project. We consider locality, levelling of land, examine
                  electricity and water also gather basic information of
                  surrounding that can help us test feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2} alt="Step Two" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once financial feasibility is analysed we start working on the
                  Construction of the Project. Based on site feasibility and
                  market research we choose between NVPH or Fan-Pad system and
                  also hydroponic system between NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3} alt="Step Three" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management{" "}
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once construction is completed, we work on automating the farm
                  using IoT devices as well as integrating with farm management.
                  At this stage, we also start training your field team to make
                  sure we continuously grow high quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4} alt="Step Four" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Markerting Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once our field team is ready and the complete farm is
                  automated, Our team of agronomists, sales and planning starts
                  working on the contract and set a selling mechanism for your
                  farm, with a schedule of crops.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="container my-4 d-flex justify-content-center">
              <div className="table-responsive">
                <h3 className="text-center">
                  <kbd>Physical Specs</kbd>
                </h3>
                <table className="table table-custom table-striped table-bordered border-dark text-center">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Specification</th>
                      <th scope="col">Options/Range</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Gutter Height</td>
                      <td>4m - 5m</td>
                    </tr>
                    <tr>
                      <td>Ridge Height</td>
                      <td>5m - 6m</td>
                    </tr>
                    <tr>
                      <td>Top Vent</td>
                      <td>Top Closed</td>
                    </tr>
                    <tr>
                      <td>Grid Size</td>
                      <td>8m X 4m</td>
                    </tr>
                    <tr>
                      <td>Gutter Slope</td>
                      <td>1.4% - 2%</td>
                    </tr>
                    <tr>
                      <td>Gutter Thickness</td>
                      <td>1.5mm/1.6mm/1.8mm/2mm RGCPL Processed</td>
                    </tr>
                    <tr>
                      <td>Apron Height</td>
                      <td>0.5m/1m/1.5m</td>
                    </tr>

                    <tr>
                      <td>GI Pipe</td>
                      <td>1000GSM - 1200GSM</td>
                    </tr>
                    <tr>
                      <td>Nuts, Bolts and Connectors</td>
                      <td>High Tensile Strength and Galvanized</td>
                    </tr>
                    <tr>
                      <td>Foundation</td>
                      <td>60 OD with 2mm Thickness</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
          <Col>
            <div className="container my-4 d-flex justify-content-center">
              <div className="table-responsive">
                <h3 className="text-center">
                  <kbd>Structural Specs</kbd>
                </h3>
                <table className="table table-custom table-striped table-bordered border-dark text-center">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Specification</th>
                      <th scope="col">Options/Range</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Cooling System</td>
                      <td>4 RGCPL Cooling Systems</td>
                    </tr>
                    <tr>
                      <td>Cladding Material</td>
                      <td>RGCPL Ginegar Polyfilm</td>
                    </tr>
                    <tr>
                      <td>Shadenet</td>
                      <td>RGCPL Aluminet/Shade Net</td>
                    </tr>
                    <tr>
                      <td>Insect Net</td>
                      <td>Insect Net (40 Mesh, 50 Mesh)</td>
                    </tr>
                    <tr>
                      <td>Top Vent</td>
                      <td>Closed</td>
                    </tr>

                    <tr>
                      <td>Secondary Layer</td>
                      <td>Standard Manual/Manual with Accessories/Automized</td>
                    </tr>

                    <tr>
                      <td>Construction</td>
                      <td>GI Pipe Frame</td>
                    </tr>
                    <tr>
                      <td>Door</td>
                      <td>RGCPL Aluminum Frame Double Sliding Door</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default ClimateControlled;
