import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/gallery/product1.webp";
import CardImg2 from "../../Assets/gallery/product2.webp";
import CardImg3 from "../../Assets/gallery/product4.webp";
import CardImg4 from "../../Assets/gallery/product3.webp";
import CardImg5 from "../../Assets/gallery/product5.webp";
import Arugula from "../../Assets/arugula2.webp";
import Celery from "../../Assets/celery.webp";
import Kale from "../../Assets/kale3.webp";
import Spinach from "../../Assets/spinach.webp";
import babyspinach from "../../Assets/babyspinach.webp";
import swisschard from "../../Assets/swisschard.webp";
function LeafyGreens() {
  return (
    <Container fluid>
      <Row className="headingimg">
        <Col className="m-5">
          <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
            {" "}
            Products
          </h1>
          <div className="text-white m-4 fw-bold text-center">
            <a href="#" className=" text-white m-3 text-decoration-none">
              Home
            </a>
            <i className="fa-solid fa-circle-arrow-right fa-beat"></i>
            <a className="text-white text-decoration-none m-3">Products</a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h2 className="text-green m-3 text-center fw-bold">
            Our Fresh Produce
          </h2>
          <h6 className="text-center m-4 fw-bold">
            All our produce is fresh, nutritious and hand-picked. <br /> We are
            dedicated to deliver to you the top-shelf produce.
          </h6>
        </Col>
      </Row>
      
      <Row className="p-2">
        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg1} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Vine Crops
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg2} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Lettuces
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg3} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Leafy Greens
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

       
      </Row>

      <Row className="row justify-content-center">
      <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg4} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Fresh Herbs
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg5} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Floriculture
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h1 className="text-green m-3 text-center fw-bold">Leafy Greens</h1>
          
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Arugula
              </h2>
              <p className="text-center ">
                Arugula is a zesty and delicious aromatic salad green.
                <br />
                Flavour: It gives a tangy, mustard-like flavour
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>It is rich in vitamin A, C, K</li>
                    <li>Contains calcium and potassium</li>
                    <li>Rich in Folate, a B Vitamin</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Helps in improving heart health</li>
                    <li>Serves as a natural cancer treatment</li>
                    <li>Lowers inflammation</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={Arugula} alt="leafy greens hydroponic farming" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img src={Celery} alt="leafy greens polyhouse" className="img-fluid p-3" />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Celery
              </h2>
              <p className="text-center ">
                Celery- A low-calorie snack with crunchy stalks
                <br />
                Flavour: Watery with a little salty taste and a lingering
                bitterness
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Contains vitamins A, C and K</li>
                    <li>A rich source of phytonutrients</li>
                    <li>Source of beta carotene and flavonoids</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Reduces inflammation</li>
                    <li>Aids in maintaining digestive health</li>
                    <li>Promotes the cardiovascular system</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Curly Kale
              </h2>
              <p className="text-center ">
                Kale, the makeup of most health juices, is one of the most
                nutrient-dense foods on the planet.
                <br />
                Flavour: A unique bitter, savoury-robust flavour.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Rich source of Folate</li>
                    <li>Contains more vitamin C than other leafy greens</li>
                    <li>
                      Contains alpha-linolenic acid, an omega-3 fatty acid
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Promotes heart health</li>
                    <li>Fights inflammation</li>
                    <li>Supports healthy vision</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={Kale} alt="shadenet farming" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img src={Spinach} alt="hydoponics" className="img-fluid p-3" />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Spinach
              </h2>
              <p className="text-center ">
                Loaded with nutrients and goodness, spinach is one of the
                nutritious foods on earth.
                <br />
                Flavour: Mild and sweet to taste.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Rich in Vitamins A, C, K</li>
                    <li>Enriched with Iron, folate and Potassium</li>
                    <li>Contains powerful flavonoid antioxidants</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Improves cognitive functions</li>
                    <li>Improves haemoglobin</li>
                    <li>Supports skin health</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Baby Spinach
              </h2>
              <p className="text-center ">
                Loaded with nutrients and goodness, spinach is one of the
                nutritious foods on earth.
                <br />
                Flavour: Mild and sweet to taste.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Rich in Vitamins A, C, K</li>
                    <li>Enriched with Iron, folate and Potassium</li>
                    <li>Contains powerful flavonoid antioxidants</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Improves cognitive functions</li>
                    <li>Improves haemoglobin</li>
                    <li>Supports skin health</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={babyspinach} alt="spinach hydroponic farming" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img src={swisschard} alt="swisschard farming" className="img-fluid p-3" />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Swiss Chard
              </h2>
              <p className="text-center ">
                Swiss chard is actually native to the Mediterranean
                <br />
                Swiss chard blends beautifully with basil in a cheesy, nutty
                pesto
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Rich in Vitamins A, C, K</li>
                    <li>
                      Good source of iron, copper, potassium, calcium, and
                      vitamin E
                    </li>
                    <li>High in antioxidants</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>
                      Promotes Digestion by effectively eliminating toxins out
                      of the body
                    </li>
                    <li>
                      Help lower high blood pressure and high cholesterol levels
                    </li>
                    <li>
                      High in fiber and low in calories, promotes weight loss
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default LeafyGreens;
