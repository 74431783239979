import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory22 from "../../Assets/accessory22.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Greenhouse_Spring_Clip_Wire_Fastening_Hook = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 68,
      name: "Greenhouse Spring Clip (Wire Fastening Hook)",
      price: 250,
      image: accessory22,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory22}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Greenhouse Spring Clip (Wire Fastening Hook)
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Greenhouse Spring Clip or Wire Fastening Hook is an
                  essential tool used to fasten wire or netting securely in
                  greenhouses. This small but crucial component plays an
                  important role in ensuring the stability of nets, wires, or
                  covers inside or around the greenhouse structure.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized
                      Steel (GI)
                    </li>
                    <li>
                      <span className="fw-bold">Size:</span> Standard size
                      suitable for greenhouse wiring
                    </li>
                    <li>
                      <span className="fw-bold">Type:</span> Hook-based
                      fastening system
                    </li>

                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 5-6 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    Corrosion-resistant: Galvanized steel construction ensures
                    rust protection.
                  </li>
                  <li>
                    {" "}
                    Use: Can be used in various points within the greenhouse for
                    netting, shading, or wire attachments.
                  </li>
                  <li>
                    Durable: Designed for long-term outdoor use, withstanding
                    moisture and temperature changes.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">
          Importance of Greenhouse Spring Clip (Wire Fastening Hook):
        </h5>
        <p className="text-justify">
          <ul>
            <li>
              Wire and Net Stability: Ensures that wires and nets remain taut
              and properly secured in place, which is important for crop
              protection and system support.
            </li>
            <li>
              Prevents Displacement: Keeps coverings or wiring from slipping out
              of place, particularly in areas where wind or other environmental
              forces are strong.
            </li>
            <li>
              Lightweight and Easy to Install: Its lightweight design makes it
              simple to install and remove without compromising the stability of
              the setup.
            </li>
          </ul>{" "}
        </p>

        <h5 className="mt-3">
          Applications of Greenhouse Spring Clip (Wire Fastening Hook):
        </h5>
        <p className="text-justify">
          <ul>
            <li>
              Used for fastening wires, shade nets, and other greenhouse
              coverings, providing stable connections.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Greenhouse_Spring_Clip_Wire_Fastening_Hook;
