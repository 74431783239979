import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabpHControllerWiFi from "../../Assets/Bluelab pH Controller Wi-Fi.webp";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_pH_Controller_WiFi = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 15,
      name: "Bluelab pH Controller Wi-Fi",
      price: 50400,
      image: BluelabpHControllerWiFi,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabpHControllerWiFi}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab pH Controller Wi-Fi</h2>
            <p className="product-price">₹{50400}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>24/7 Reservoir pH Control at Your Fingertips</h5>
              Monitor and manage your reservoir pH levels anytime, anywhere,
              directly from your mobile device.
              <ul className="mt-3">
                <li>
                  <span className="fw-bold">Wi-Fi Connectivity:</span> Enjoy built-in Wi-Fi capabilities that you
                  can disable if needed.
                </li>
                <li>
                <span className="fw-bold">Mobile Access:</span> View and control your system via your
                  smartphone. Visit Edenic.io for more details.
                </li>
                <li>
                <span className="fw-bold">Safety Features:</span> Safety lockouts are integrated to prevent
                  excessive dosing, ensuring plant safety.
                </li>
                <li>
                <span className="fw-bold">Automatic Resumption:</span> The system automatically resumes dosing
                  after a power outage, maintaining continuous operation.
                </li>
                <li>
                <span className="fw-bold">Precision Dosing:</span> Achieve accurate pH adjustments with the
                  built-in Bluelab peristaltic pump.
                </li>
                <li>
                <span className="fw-bold">pH Control Flexibility:</span> Effectively control pH levels in both
                  alkali (up) and acid (down) directions.
                </li>
                <li>
                <span className="fw-bold">Alerts and Notifications:</span> High and low alarms notify you when
                  parameters fall outside the desired range.
                </li>
                <li>
                <span className="fw-bold">Efficient Mixing:</span> The dosing routine is designed for effective
                  mixing before the next dose is administered.
                </li>
                <li>
                <span className="fw-bold">Optimal Positioning:</span> Equipped with 2-meter (6-foot) cable
                  lengths for ideal positioning and viewing.
                </li>
                <li>
                <span className="fw-bold">Variable Speed Pump:</span> The built-in pump offers a maximum flow
                  rate of 120 ml/min (4.06 fl oz) for efficient dosing.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_pH_Controller_WiFi;
