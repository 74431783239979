import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCart } from "../../Contexts/CartContext";
import "../../App.css";

import AutogrowIntelliClimateKit from "../../Assets/Autogrow IntelliClimate Kit.webp";
import BatteryCapForTruncheonMeterV2 from "../../Assets/Battery Cap for Truncheon Meter V2.webp";
import BluelabComboMeterWithLeappHProbe from "../../Assets/Bluelab Combo Meter with Leap pH Probe.webp";
import BluelabComboMeter from "../../Assets/Bluelab Combo Meter.webp";
import BluelabCommercialTruncheonNutrientMeter from "../../Assets/Bluelab Commercial Truncheon Nutrient Meter.webp";
import BluelabLeappHProbe from "../../Assets/Bluelab Leap pH Probe.webp";
import BluelabMultimediapHMeter from "../../Assets/Bluelab Multimedia pH Meter.webp";
import BluelabpHProbe5M from "../../Assets/Bluelab pH Probe 5M.webp";
import BluelabpHProbe from "../../Assets/Bluelab pH Probe.webp";
import BluelabPulseMultimedia from "../../Assets/Bluelab Pulse Multimedia.webp";
import BluelabTruncheonNutrientMeter from "../../Assets/Bluelab Truncheon Nutrient Meter.webp";
import ShroudforTruncheonMeterV2 from "../../Assets/Shroud for Truncheon Meter V2.webp";

import CartModal from "../../Components/CartModal";

function Automation_Products_Meters() {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const products = [
    // {
    //   id: 29,
    //   name: "Autogrow IntelliClimate Kit",
    //   price: 100,
    //   image: AutogrowIntelliClimateKit,
    //   link: "/Autogrow_IntelliClimate_Kit",
    //   description: "Description for Product 1",
    // },
    {
      id: 30,
      name: "Battery Cap for Truncheon Meter V2",
      price: 588,
      image: BatteryCapForTruncheonMeterV2,
      link: "/Battery_Cap_for_Truncheon_Meter_V2",
      description: "Description for Product 2",
    },
    {
      id: 31,
      name: "Bluelab Combo Meter with Leap pH Probe",
      price: 29484,
      image: BluelabComboMeterWithLeappHProbe,
      link: "/Bluelab_Combo_Meter_with_Leap_pH_Probe",
      description: "Description for Product 2",
    },
    {
      id: 32,
      name: "Bluelab Combo Meter",
      price: 24948,
      image: BluelabComboMeter,
      link: "/Bluelab_Combo_Meter",
      description: "Description for Product 2",
    },
    {
      id: 33,
      name: "Bluelab Commercial Truncheon Nutrient Meter",
      price: 11340,
      image: BluelabCommercialTruncheonNutrientMeter,
      link: "/Bluelab_Commercial_Truncheon_Nutrient_Meter",
      description: "Description for Product 2",
    },
    {
      id: 34,
      name: "Bluelab Leap pH Probe",
      price: 10248,
      image: BluelabLeappHProbe,
      link: "/Bluelab_Leap_pH_Probe",
      description: "Description for Product 2",
    },
    {
      id: 35,
      name: "Bluelab Multimedia pH Meter",
      price: 22680,
      image: BluelabMultimediapHMeter,
      link: "/Bluelab_Multimedia_pH_Meter",
      description: "Description for Product 2",
    },
    {
      id: 36,
      name: "Bluelab pH Probe 5M",
      price: 7140,
      image: BluelabpHProbe5M,
      link: "/Bluelab_pH_Probe_5ms",
      description: "Description for Product 2",
    },
    {
      id: 37,
      name: "Bluelab pH Probe",
      price: 6384,
      image: BluelabpHProbe,
      link: "/Bluelab_pH_Probe",
      description: "Description for Product 2",
    },
    {
      id: 38,
      name: "Bluelab Pulse Multimedia EC/MC Meter",
      price: 25704,
      image: BluelabPulseMultimedia,
      link: "/Bluelab_Pulse_Multimedia",
      description: "Description for Product 2",
    },
    {
      id: 39,
      name: "Bluelab Truncheon Nutrient Meter",
      price: 11340,
      image: BluelabTruncheonNutrientMeter,
      link: "/Bluelab_Truncheon_Nutrient_Meter",
      description: "Description for Product 2",
    },
    {
      id: 40,
      name: "Shroud for Truncheon Meter V2",
      price:420,
      image: ShroudforTruncheonMeterV2,
      link: "/Shroud_for_Truncheon_Meter_V2",
      description: "Description for Product 2",
    },
  ];
  const itemsPerPage = 12;
  const totalPages = Math.ceil(products.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const currentProducts = products.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  return (
    <Container>
      <Row>
        {currentProducts.map((product) => (
          <Col key={product.id} xs={12} sm={6} md={3}>
            <Link
              to={product.link}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card className="product-card">
                <Card.Img
                  variant="top"
                  src={product.image}
                  alt={product.name}
                  className="product-image"
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    {product.name}
                  </Card.Title>
                  <Card.Text className="text-center">
                    Price: ₹{product.price}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </Container>
  );
}
export default Automation_Products_Meters;
