import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import ReplacementpHControllerTubing from "../../Assets/Replacement pH Controller Tubing (13ft  4m).webp";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Replacement_pH_Controller_Tubing = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 27,
      name: "Replacement pH Controller Tubing (13ft / 4m)",
      price: 5712,
      image: ReplacementpHControllerTubing,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={ReplacementpHControllerTubing}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Replacement pH Controller Tubing (13ft / 4m)
            </h2>
            <p className="product-price">₹{5712}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>Bluelab pH Controller Peristaltic Pump Tube Replacement</h5>
              Compatible with both the Bluelab pH Controller and Bluelab pH
              Controller Connect, this replacement tube ensures smooth and
              accurate pH dosing. Designed for easy installation, it helps
              maintain optimal system performance.
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Replacement_pH_Controller_Tubing;
