import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabProbeCareKitpHImg from "../../Assets/Bluelab Probe Care Kit - pH.webp";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_Probe_Care_Kit_pH = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 45,
      name: "Bluelab Probe Care Kit - pH",
      price: 1848,
      image: BluelabProbeCareKitpHImg,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabProbeCareKitpHImg}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab Probe Care Kit - pH</h2>
            <p className="product-price">₹{1848}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>Comprehensive Care for Your pH Probes</h5>
              The Bluelab Probe Care Kit – pH provides everything you need to
              effectively clean, calibrate, and maintain your pH probes. This
              essential kit includes:
              <ul className="mt-3">
                <li>
                  Probe Care Instructions for easy and effective maintenance
                </li>
                <li>
                  18 ml Single-Use Bluelab Solution Sachets: Two each of pH 7.0,
                  pH 4.0, and KCl solutions
                </li>
                <li>Bluelab pH Probe Cleaner to ensure optimal performance</li>
                <li>
                  Toothbrush specifically designed for thorough cleaning of your
                  pH probe
                </li>
                <li>
                  <i>Please note: Packaging may vary.</i>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_Probe_Care_Kit_pH;
