import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory26 from "../../Assets/accessory26.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Pulley_with_Lock_Mechanism_Gable_Shaped_Polyhouse_Pulley = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 72,
      name: "Pulley with Lock Mechanism (Gable Shaped Polyhouse Pulley)",
      price: 250,
      image: accessory26,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory26}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Pulley with Lock Mechanism (Gable Shaped Polyhouse Pulley)
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Pulley with Lock Mechanism from RGCPL is essential for
                  efficient ventilation control in polyhouses. Featuring a
                  smooth-operating plastic pulley with a galvanized iron lock,
                  this product enables easy adjustment of the polyhouse sides
                  for optimal air circulation and environmental control. The
                  built-in locking mechanism secures the roll-up system in place
                  during strong winds or other adverse conditions. Ideal for
                  polyhouses of all sizes, this pulley helps maintain the
                  internal climate, ensuring better plant growth and protection
                  from external elements.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold"> Material:</span> Plastic with a
                      Galvanized Iron (GI) locking mechanism (GI)
                    </li>
                    <li>
                      <span className="fw-bold"> Color</span> Black and Silver
                    </li>
                    <li>
                      <span className="fw-bold">Diameter:</span> 3 inches
                      (pulley wheel size)
                    </li>
                    <li>
                      <span className="fw-bold">Thickness:</span> 2.5 mm
                    </li>

                    <li>
                      <span className="fw-bold">Usage/Application:</span> Used
                      in rolling and ventilation systems in polyhouses
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 5-6 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    <span className="fw-bold">Smooth Operation:</span> Ensures
                    easy roll-up and down of plastic films for side ventilation.
                  </li>
                  <li>
                    <span className="fw-bold">Durable Design:</span> Made from a
                    combination of high-quality plastic and galvanized metal to
                    ensure long-lasting use.
                  </li>
                  <li>
                    <span className="fw-bold">Locking mechanism:</span> The
                    pulley comes with a built-in lock that secures the roll-up
                    system in place when needed.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">Why Choose RGCPL’s Pulley with Lock Mechanism?</h5>
        <p className="text-justify">
          Known for its smooth, reliable operation, this pulley is designed to
          last. The locking feature ensures security during rough weather
          conditions. Easy to install and integrate into any roll-up system for
          polyhouse sides.
        </p>
        <h5 className="mt-3">Key Benefits of Pulley with Lock Mechanism:</h5>
        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Smooth Operation:</span> Ensures
              hassle-free rolling of plastic films for ventilation.
            </li>
            <li>
              <span className="fw-bold">Durable:</span> Built to last even under
              continuous exposure to sunlight and moisture.
            </li>
            <li>
              <span className="fw-bold">Weather-resistant:</span> Made of
              galvanized materials that prevent rust and degradation.
            </li>
            <li>
              <span className="fw-bold">Locking Mechanism:</span> Ensures that
              the roll-up system stays in place, preventing unwanted opening or
              closing.
            </li>
          </ul>{" "}
        </p>

        <h5 className="mt-3">Importance of Pulley with Lock Mechanism:</h5>
        <p className="text-justify">
          <ul>
            <li>
              The pulley with a lock mechanism plays a vital role in the
              ventilation and environmental control system of a polyhouse.
            </li>{" "}
            <li>
              {" "}
              By allowing easy adjustment of the polyhouse sides, it helps
              regulate temperature and humidity inside, which is crucial for
              optimal plant growth.
            </li>
            <li>
              {" "}
              This mechanism also ensures that the side walls can be locked
              securely in place to protect plants during harsh weather
              conditions.
            </li>
          </ul>{" "}
        </p>

        <h5 className="mt-3">Applications of Pulley with Lock Mechanism:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Facilitates the rolling-up system in polyhouses to allow for
              proper air circulation.
            </li>
            <li>
              Used to easily adjust side ventilation based on temperature and
              climate conditions.
            </li>
            <li>
              Essential for both commercial and small-scale greenhouses that
              require environmental control.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Pulley_with_Lock_Mechanism_Gable_Shaped_Polyhouse_Pulley;
