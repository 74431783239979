import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BlbConductivityPen from "../../Assets/bluelab-conductivity-pen.webp";
import "../../App.css"; // Import your CSS file for styles
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Product4 = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 4,
      name: "Bluelab Conductivity Pen",
      price: 7560,
      image: BlbConductivityPen,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BlbConductivityPen}
                  alt="Product 4"
                  className="d-block w-100 carousel-image"
                />
              </div>
              {/* Optionally: You can remove or add more images in the carousel */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab Conductivity Pen</h2>{" "}
            {/* Update the product name */}
            <p className="product-price">₹{7560}</p>{" "}
            {/* Dynamically display product price */}
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3">
              <h4>
                A durable handheld tool designed for measuring conductivity and
                temperature on the go
              </h4>
              This device is fully waterproof, offering more than just water
              resistance. It features selectable measurement units, allowing you
              to tailor the settings to your preferences. The flat electrode
              simplifies cleaning and maintenance, making it easier to keep in
              optimal condition. With a hold reading function, capturing
              measurements is effortless, while automatic temperature
              compensation ensures accuracy in any environment. The auto-off
              feature helps conserve battery life, and it comes with a
              pre-installed AAA alkaline battery, ready to use right out of the
              box.
              <h4>Key features include:</h4>
              <ul>
                <li>Customizable measurement units for user preferences</li>
                <li>Flat electrode for easy cleaning and maintenance</li>
                <li>Fully waterproof design for tough conditions</li>
                <li>
                  Hold reading function for effortless measurement capture
                </li>
                <li>
                  Automatic temperature compensation for precise readings in any
                  environment
                </li>
                <li>Auto-off function to extend battery life</li>
                <li>Includes 1 x AAA alkaline battery for immediate use</li>
              </ul>
            </p>{" "}
            {/* Update the product description */}
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Product4;
