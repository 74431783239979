import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory2 from "../../Assets/accessory2.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Shade_Net_Pulley_for_Greenhouse_Systems = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 48,
      name: "Shade Net Pulley for Greenhouse Systems",
      price: 2,
      image: accessory2,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory2}
            alt="Plastic Shade Net Stitching Pins for Polyhouse"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Shade Net Pulley for Greenhouse Systems
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Shade Net Pulley is designed to facilitate the movement of
                  shade nets in greenhouses and shade net houses. It allows easy
                  adjustment of the nets to control light and temperature within
                  the structure. Made from high-quality galvanized iron, this
                  pulley is both durable and weather-resistant.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Pieces.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized Iron
                      (GI)
                    </li>
                    <li>
                      <span className="fw-bold">Pulley Type:</span> For
                      Greenhouse Curtains
                    </li>
                    <li>
                      <span className="fw-bold">Usage/Application</span>{" "}
                      Greenhouse Curtain Movement
                    </li>
                    <li>
                      <span className="fw-bold">Surface Finish:</span>{" "}
                      Rust-Resistant Galvanized Coating
                    </li>
                    <li>
                      <span className="fw-bold">Mechanism:</span> Smooth Pulley
                      Operation
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> Metallic Finish
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      1000 Piece
                    </li>
                    <li>
                      <span className="fw-bold">Manufactured by:</span> Royal
                      Green House (In-House Production – India)
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>Sturdy pulley mechanism</li>
                  <li>Designed for greenhouse curtain control</li>
                  <li>Corrosion-resistant galvanized iron</li>
                  <li>Smooth operation for easy curtain management</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Manufactured by:</span> Royal
                      Green House
                    </li>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0001
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days{" "}
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Gujarat, Maharashtra{" "}
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span>{" "}
                      10,000 Pieces Per Day{" "}
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Sturdy
                      packaging to ensure product safety during transit{" "}
                    </li>

                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment{" "}
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">
          Why Choose Royal Green House for Shade Net Pulley?
        </h5>
        <p className="mt-1 text-justify">
          Royal Green House’s Shade Net Pulley is designed to provide efficient
          control over shade nets used in greenhouses, polyhouses, and net
          houses. These pulleys are essential for easily adjusting the position
          of shade nets, which regulate light and temperature for optimal plant
          growth. Constructed from galvanized iron, these pulleys offer both
          strength and durability, ensuring long-term performance even in harsh
          agricultural environments.
        </p>
        <h5 className="mt-3">
          Key Benefits of Royal Green House’s Shade Net Pulley:
        </h5>
        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Durable and Weather-Resistant:</span>{" "}
              Made from galvanized iron, these pulleys are resistant to rust and
              environmental wear, ensuring reliability for long-term use.
            </li>
            <li>
              <span className="fw-bold">Smooth and Efficient Operation:</span>{" "}
              The pulley system allows easy raising and lowering of shade nets,
              helping to regulate light and heat within the greenhouse or net
              house.
            </li>
            <li>
              <span className="fw-bold">Sturdy Construction:</span> Built for
              heavy-duty use in agricultural applications, providing stability
              and efficiency in daily operations.
            </li>
            <li>
              <span className="fw-bold">Cost-Effective:</span> High-quality
              construction at an affordable price, ideal for large-scale
              installations.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Application of Shade Net Pulley:</h5>
        <p className="text-justify">
          The Shade Net Pulley is vital for controlling shade nets in
          polyhouses, greenhouses, and net houses. By adjusting the shade nets
          using this pulley, farmers can regulate sunlight exposure and
          temperature inside the structure, ensuring optimal conditions for
          crops and plants. These pulleys are commonly used in various
          agricultural setups, offering smooth operation and ease of use.
          <br />
          For inquiries and orders, contact Royal Green House today for reliable
          net house repair solutions!
          <h5 className="mt-3">Contact Us:</h5>
          <div className="text-sm-start text-center text-muted">
            <i className="fas fa-phone-alt"></i>
            <a href="tel:+919960991166" className="ms-2">
              {" "}
              +91-9960-991-166
            </a>
          </div>
          <br />
          <div className="text-sm-start text-center text-muted">
            <i className="fas fa-envelope"></i>
            <a
              href="mailto:info@royalgreenhouse.co.in"
              target="_blank"
              className="ms-2"
            >
              info@royalgreenhouse.co.in
            </a>
          </div>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Shade_Net_Pulley_for_Greenhouse_Systems;
