import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import cost_blog_img from "../../Assets/cost_blog_img.webp";
import "./blog.css";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function Lowcost_polyhouse_setup() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={cost_blog_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "90%" }}
          alt="cost of hydroponic and polyhouse farming"
        />
      </div>

      <Row className="m-3">
        <Col className="p-4 textJustify" sm={12} md={10} lg={8} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">
              Low Cost Polyhouse Setup
            </h1>{" "}
            <p className="pb-4">
              When planning agricultural investments, particularly in protected
              cultivation, understanding the polyhouse farming cost and
              hydroponic farming cost is crucial. At Royal Green House, we
              specialize in designing cost-effective, high-quality cultivation
              structures tailored to various crop needs. Here's an overview of
              the costs and benefits associated with different setups:
            </p>
            <p></p>
            <p>
              <ol>
                <li>
                  <span className="fw-bold">
                    <a href="/Nvph">NVPH Polyhouse</a> - ₹1200 per sqm:
                  </span>
                </li>
                <p>
                  The Natural Ventilated Polyhouse (NVPH) offers a balance
                  between cost and efficiency. This structure is perfect for
                  regions with moderate climates, providing optimal air
                  circulation to support crops while shielding them from extreme
                  weather. With a polyhouse farming cost of ₹1200 per sqm, it
                  allows farmers to regulate temperature and humidity
                  effectively.
                </p>
                <li>
                  <span className="fw-bold">
                    <a href="/ClimateControlled">Fan Pad Polyhouse</a> - ₹1800
                    per sqm:
                  </span>
                </li>
                <p>
                  Known for advanced climate control, the Fan Pad Polyhouse is
                  ideal for growing high-value crops requiring stable
                  conditions. Featuring an evaporative cooling system, it
                  maintains optimal temperatures, ensuring superior yield
                  quality. The polyhouse farming cost of ₹1800 per sqm makes it
                  a premium choice for serious cultivators.{" "}
                </p>
                <li>
                  <span className="fw-bold">
                    <a href="/TunnelShadeHouse">Tunnel Net House</a> - ₹700 per
                    sqm:
                  </span>
                </li>
                <p>
                  Affordable and efficient, the Tunnel Net House provides basic
                  protection against pests and mild climate fluctuations. Its
                  polyhouse farming cost of ₹700 per sqm makes it a great option
                  for farmers looking to protect crops with a minimal budget.
                </p>
                <li>
                  <span className="fw-bold">
                    <a href="/Flat_Shade_Net_House">Flat Net House</a> - ₹600
                    per sqm:
                  </span>
                </li>
                <p>
                  This economical solution is perfect for nurseries and
                  shade-loving crops. At ₹600 per sqm, the polyhouse farming
                  cost for a Flat Net House is highly affordable, offering
                  excellent protection against pests and direct sunlight while
                  enhancing air circulation.
                </p>
                <li>
                  <span className="fw-bold">
                    <a href="/RainProtectedWireRopeNetHouse">
                      Rain Protected Wire Rope Net House
                    </a>{" "}
                    - ₹900 per sqm:
                  </span>
                </li>
                <p>
                  Designed for areas with high rainfall, this structure shields
                  crops from excess rain and humidity. With a polyhouse farming
                  cost of ₹900 per sqm, it’s an excellent investment for
                  rain-sensitive crops.
                </p>
              </ol>
            </p>
            <p>
              For those interested in hydroponics, integrating hydroponic
              farming systems within these structures ensures efficient water
              usage and higher yields. The hydroponic farming cost depends on
              the chosen structure and setup but is a highly sustainable
              investment for modern agriculture.
            </p>
            <p>
              Each structure caters to specific farming needs, providing
              solutions for a wide range of crops and climates. Whether you're
              exploring polyhouse farming cost for traditional agriculture
              or hydroponic farming cost for advanced methods, our tailored
              solutions guarantee optimal returns.
            </p>
            <p>
              For further inquiries or assistance in choosing the right
              structure, you can reach out to us via email at{" "}
              <a href="mailto:info@royalgreenhouse.co.in" target="_blank">
                info@royalgreenhouse.co.in
              </a>{" "}
              or visit us at our Satara location:
            </p>
            <p>
              <a href="https://maps.app.goo.gl/zaBudTaYEaCEqjAJ8">
                <i
                  className="fas fa-map-marker-alt"
                  style={{ color: "green", marginRight: "5px" }}
                ></i>
                #F2/B, Sairatn Building, Survey No 517/5, Visava Naka, Satara,
                Maharashtra, 415002
              </a>
              <br />
              <a href="tel:+919960991166">
                <i
                  className="fas fa-phone"
                  style={{ color: "green", marginRight: "5px" }}
                ></i>
                +91-9960-991-166
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default Lowcost_polyhouse_setup;
