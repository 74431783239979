import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import factory_img from "../../Assets/gallery/factory12.webp";
import "./blog.css";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function Hydroponic_innovation_in_india() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

  return () => clearTimeout(timer);
  }, []);
  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={factory_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "90%" }}
          alt="top 10 hydroponics crops"
        />
      </div>

      <Row className="m-3">
        <Col className="p-4 textJustify" sm={12} md={10} lg={8} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">
              Hydroponic Innovation in India
            </h1>{" "}
            <p className="pb-4">
              In the realm of modern agriculture, Royal Green House (RGCPL)
              stands as a leader in polyhouse construction and hydroponic
              solutions, empowering Indian growers with cutting-edge technology
              and robust setups that redefine sustainable farming. From
              climate-controlled polyhouses to precision hydroponic systems,
              RGCPL brings expertise, innovation, and a passion for sustainable
              agriculture. Here’s how RGCPL is shaping the future of farming in
              India.
            </p>
            <h4>Why Choose RGCPL?</h4>
            <ol>
              <li className="pb-4">
                <h6>Expertise in Polyhouse Construction</h6>
                <p>
                  RGCPL excels in constructing high-quality polyhouses that
                  provide a controlled environment, protecting crops from
                  extreme weather conditions and ensuring optimal growth
                  conditions. Our team understands the nuances of various crop
                  requirements, climate conditions, and structural needs,
                  creating tailor-made solutions for growers across India. With
                  a factory in India producing in-house guttering, we ensure
                  quality control and cost-effectiveness in every project.
                </p>
              </li>
              <li className="pb-4">
                <h6>Hydroponic Solutions for Precision Farming</h6>
                <p>
                  With hydroponics revolutionizing traditional agriculture,
                  RGCPL has emerged as a front-runner in providing advanced
                  hydroponic setups. Our systems are designed to maximize water
                  efficiency, nutrient delivery, and yield quality. As the
                  authorized distributor of Bluelab’s precision tools from New
                  Zealand, we equip our clients with the latest technology to
                  monitor and optimize nutrient levels, pH, and other key growth
                  factors.
                </p>
              </li>
              <li className="pb-4">
                <h6>Comprehensive Range of Services</h6>
                <p>
                  RGCPL offers a complete suite of services, from consultation
                  and design to construction and maintenance. Whether you're a
                  commercial grower or a beginner in hydroponics, our team of
                  experts is committed to supporting you at every step. From
                  small setups to large-scale installations, we offer bespoke
                  solutions to meet each client's unique needs.
                </p>
              </li>
              <li className="pb-4">
                <h6>In-House Manufacturing for Quality Assurance</h6>
                <p>
                  At RGCPL, we prioritize quality in every component of our
                  structures. Our in-house manufacturing facility in India
                  enables us to produce premium gutters and other essential
                  parts, ensuring durability and long-lasting performance. This
                  not only improves the quality of the structures but also
                  reduces lead times, making projects more affordable and
                  efficient for clients.
                </p>
              </li>
              <li className="pb-4">
                <h6>Trusted by Growers Nationwide</h6>
                <p>
                  RGCPL's commitment to excellence is backed by satisfied
                  customers across India. We have successfully delivered
                  projects that have transformed farms, helping growers adopt
                  innovative methods for increased productivity and
                  profitability. Our collaborations with industry leaders like
                  Bluelab and our extensive experience in hydroponics further
                  establish our credibility and dedication to the field.
                </p>
              </li>
            </ol>
            <h4>Our Core Competencies:</h4>
            <ol>
              <li className="pb-4">
                <h6>Construction Expertise:</h6>
                <p>
                  {" "}
                  RGCPL’s construction team specializes in erecting sturdy,
                  customizable polyhouses designed for diverse crop needs. Our
                  structures are built with precision, ensuring ventilation,
                  lighting, and temperature control for maximum crop yield.
                  Every project is managed with care, keeping quality and client
                  specifications as our top priorities.
                </p>
              </li>
              <li className="pb-4">
                <h6>Hydroponic Farming Solutions:</h6>
                <p>
                  With hydroponic technology, RGCPL empowers farmers to grow
                  crops without soil, utilizing nutrient-rich solutions that
                  promote faster growth and higher yields. Our hydroponic
                  systems are ideal for water-scarce regions and urban farming
                  initiatives. By reducing dependency on soil and traditional
                  irrigation, we help farmers conserve resources while producing
                  quality crops year-round.
                </p>
              </li>
              <li className="pb-4">
                <h6>Technical Support and Training:</h6>
                <p>
                  At RGCPL, we recognize that success in agriculture requires
                  both knowledge and tools. We provide extensive training and
                  ongoing support to ensure our clients fully leverage the
                  potential of our systems. From nutrient management to
                  troubleshooting, our experts are always available to assist
                  our clients, building confidence and independence in their
                  operations.
                </p>
              </li>
              <li className="pb-4">
                <h6>Sustainable Practices for a Greener Tomorrow:</h6>
                <p>
                  {" "}
                  Sustainability is at the heart of RGCPL's vision. Our
                  polyhouses and hydroponic solutions are designed to minimize
                  resource consumption and reduce environmental impact. By
                  promoting soilless farming methods and efficient water use, we
                  are helping Indian agriculture transition towards a greener,
                  more sustainable future.
                </p>
              </li>
            </ol>
            <h4>Client-Centered Approach</h4>
            <p>
              We believe that every project is a partnership, and we work
              closely with our clients to understand their specific needs and
              challenges. From setting up a small urban farm to constructing
              large-scale polyhouse installations, we take pride in delivering
              solutions that match each client's aspirations and goals.
            </p>
            <p className="pb-4">
              Our recent projects include setups for both emerging agripreneurs
              and established agricultural companies, each tailored to fit the
              unique climate, crop, and spatial requirements. With an increasing
              demand for soilless farming methods like cocopeat growbags and
              high-tech hydroponics, RGCPL has the experience and dedication to
              fulfill diverse agricultural needs.
            </p>
            <h4>Join the RGCPL Community</h4>
            <p className="pb-4">
              Are you ready to transform your farming experience? With RGCPL,
              you’re not just investing in infrastructure—you’re investing in a
              future of sustainable and profitable agriculture. Visit [Royal
              Green House](
              <a href="https://royalgreenhouse.co.in">
                https://royalgreenhouse.co.in
              </a>
              ) to learn more about our offerings and get started on your
              journey toward agricultural innovation. Together, let's cultivate
              a brighter, greener future for India.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default Hydroponic_innovation_in_india;
