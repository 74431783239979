import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory11 from "../../Assets/accessory11.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Galvanized_Iron_Wire = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 57,
      name: "Galvanized Iron Wire",
      price: 250,
      image: accessory11,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory11}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Galvanized Iron Wire</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The RGCPL Galvanized Iron (GI) Wire is an essential fastening
                  solution for greenhouse and poly house applications. It is
                  designed for securing netting, fastening frames, and various
                  other tasks that require strong yet flexible wire. With its
                  anti-rust properties, it is ideal for outdoor use, providing a
                  durable solution in different weather conditions.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized
                      Iron
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> Metallic Silver
                    </li>
                    <li>
                      <span className="fw-bold">Diameter:</span> 2 mm
                    </li>
                    <li>
                      <span className="fw-bold">Length:</span> 100 Meters
                      (custom sizes available)
                    </li>

                    <li>
                      <span className="fw-bold">Usage/Application:</span>{" "}
                      Greenhouse Construction, Netting Installation
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 2-3 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    Eco-friendly: Manufactured with sustainable materials.
                  </li>
                  <li>
                    High strength: Strong enough to hold heavy loads without
                    breaking.
                  </li>
                  <li>
                    Corrosion-resistant: Protects against rust and degradation.
                  </li>
                  <li>Structure: GI (Galvanized Iron)</li>
                  <li>Built Type: Multi-functional</li>
                  <li>Type: Greenhouse/Poly House Structural Support</li>
                  <li>Pattern: RGCPL</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">Importance of Galvanized Iron Wire:</h5>
        <p className="text-justify">
          The galvanized iron wire plays a crucial role in keeping greenhouse
          and poly house structures intact. It is essential for securing
          coverings, netting, and other critical parts, providing flexibility
          for modifications and adjustments as required.
        </p>
        <h5 className="mt-3">Key Benefits of Galvanized Iron Wire:</h5>

        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Rust-resistant:</span> Designed to
              resist corrosion, making it ideal for long-term use in outdoor
              environments.
            </li>
            <li>
              <span className="fw-bold">Flexible yet strong:</span>Can be bent
              and twisted easily while still maintaining strength for fastening.
            </li>
            <li>
              <span className="fw-bold">Cost effective:</span> Provides
              high-quality performance at a competitive price point.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Applications:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Fastening components within greenhouses such as poles, netting,
              and covers.
            </li>
            <li>
              Used in poly house construction for attaching or securing
              different parts and materials.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Galvanized_Iron_Wire;
