import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory28 from "../../Assets/accessory28.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Greenhouse_Pipe_Bracket = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 74,
      name: "Greenhouse Pipe Bracket (Corner Mount Bracket)",
      price: 250,
      image: accessory28,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory28}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Greenhouse Pipe Bracket (Corner Mount Bracket)
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Greenhouse Pipe Bracket is a crucial component for
                  reinforcing corner joints in polyhouse and greenhouse
                  structures. Its durable design provides long-term stability,
                  especially in areas prone to high winds or stress.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized Iron
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> Silver
                    </li>

                    <li>
                      <span className="fw-bold">Dimensions:</span> 6 inches x 2
                      inches (approx.)
                    </li>
                    <li>
                      <span className="fw-bold">Thickness:</span> 2 mm
                    </li>
                    <li>
                      <span className="fw-bold">Usage/Application:</span>Used
                      for corner reinforcement and connection of polyhouse or
                      greenhouse pipes
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 7-8 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    <span className="fw-bold">Heavy-Duty:</span> Provides strong
                    reinforcement at pipe joints.
                  </li>
                  <li>
                    <span className="fw-bold">Corrossion-Resistant:</span>
                    Galvanized steel ensures protection against rust.
                  </li>
                  <li>
                    <span className="fw-bold">Easy Installation:</span>{" "}
                    Pre-drilled holes for seamless attachment.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">Importance of Greenhouse Pipe Bracket:</h5>
        <p className="text-justify">
          Corner brackets are essential for maintaining the structural integrity
          of greenhouses. By reinforcing corner joints, they help distribute the
          load across the structure, preventing frame deformation and enhancing
          the overall durability of the greenhouse. Without secure corners, the
          structure may fail during extreme weather conditions.
        </p>

        <h5 className="mt-3">Applications of Greenhouse Pipe Bracket:</h5>
        <p className="text-justify">
          <ul>
            <li>Connecting and reinforcing corner joints in greenhouses.</li>
            <li>Used in both small and large polyhouse structures for additional stability.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Greenhouse_Pipe_Bracket;
