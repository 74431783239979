import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory15 from "../../Assets/accessory15.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Shadenet_stitching_pin = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 61,
      name: "Shade Net Stitching Pins for Polyhouse by Royal Green House",
      price: 5,
      image: accessory15,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory15}
            alt="Plastic Shade Net Stitching Pins for Polyhouse"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Shade Net Stitching Pins for Polyhouse by Royal Green House
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  Royal Green House manufactures Plastic Shade Net Stitching
                  Pins perfect for maintaining and repairing polyhouses and net
                  houses. Made from high-quality plastic, these pins are
                  durable, lightweight, and easy to install. Produced locally in
                  our in-house manufacturing unit in India, we provide the best
                  in quality and price for your agricultural needs.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Pieces.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>Pin Type: Modular </li>
                    <li>Thickness: 100 micron </li>
                    <li>Pin Diameter: 2 mm </li>
                    <li>Pin Length: 3 Inch </li>
                    <li>Usage/Application: Net House Repairing </li>
                    <li>Surface Finish: Polished </li>
                    <li>Product Material: Plastic </li>
                    <li>Color: Black </li>
                    <li>
                      Manufactured By*: Royal Green House (In-House Production –
                      India)
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>Modular pin type</li>
                  <li>Made of durable plastic</li>
                  <li>Polished surface for smooth use</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>Manufactured by: Royal Green House</li>
                    <li>Item Code: RGCPL-0001</li>
                    <li>Delivery Time: 7-8 Days </li>
                    <li>Port of Dispatch: Maharashtra, India </li>
                    <li>Production Capacity: 10,000 Pieces Per Day </li>
                    <li>Packaging Details: Packed in durable plastic bags </li>

                    <li>Payment Terms: Advance Payment </li>
                    <li>Payment Mode: RTGS / NEFT / IMPS</li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">
          Why Choose Royal Green House for Plastic Shade Net Stitching Pins?
        </h5>
        <p className="mt-1 text-justify">
          Royal Green House’s Plastic Shade Net Stitching Pins offer a
          lightweight yet durable solution for net house and polyhouse repair.
          Made from high-quality plastic, these pins are resistant to corrosion
          and wear, making them perfect for agricultural environments. With our
          in-house production unit in India, we provide top-notch quality at
          competitive prices.
        </p>
        <h5 className="mt-3">
          Key Benefits of Royal Green House’s Plastic Shade Net Stitching Pins:
        </h5>
        <p className="text-justify">
          <ul>
            <li>
              Lightweight and Durable: These plastic pins offer the perfect
              balance of strength and flexibility, making them easy to handle
              and highly effective.{" "}
            </li>
            <li>
              Modular Design: The modular design makes these pins versatile for
              various net house repairs.{" "}
            </li>
            <li>
              Polished Finish: Smooth surface finish allows for easy handling
              and installation.{" "}
            </li>
            <li>
              Affordable: High-quality material at an affordable price, ideal
              for large-scale repairs.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">
          Application of Plastic Shade Net Stitching Pins:
        </h5>
        <p className="text-justify">
          Royal Green House’s Plastic Shade Net Stitching Pins are ideal for
          *net house repair* and maintenance, offering a reliable and efficient
          solution. Their lightweight construction makes them easy to use, and
          they provide secure fastening for shade nets, essential for
          greenhouse, polyhouse, and net house operations.
          <br />
          For inquiries and orders, contact Royal Green House today for reliable
          net house repair solutions!
          <h5 className="mt-3">Contact Us:</h5>
          <div className="text-sm-start text-center text-muted">
            <i className="fas fa-phone-alt"></i>
            <a href="tel:+919960991166" className="ms-2">
              {" "}
              +91-9960-991-166
            </a>
          </div>
          <br />
          <div className="text-sm-start text-center text-muted">
            <i className="fas fa-envelope"></i>
            <a
              href="mailto:info@royalgreenhouse.co.in"
              target="_blank"
              className="ms-2"
            >
              info@royalgreenhouse.co.in
            </a>
          </div>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Shadenet_stitching_pin;
