import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabPeriPodM4 from "../../Assets/Bluelab-Peripod-Peristaltic-Pump-M4_PERIPODM4_1.webp";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_PeriPod_M4 = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 23,
      name: "Bluelab PeriPod M4",
      price: 117180,
      image: BluelabPeriPodM4,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabPeriPodM4}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab PeriPod M4</h2>
            <p className="product-price">₹{117180}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>
                Effortlessly automate pH and nutrient dosing in your reservoirs
              </h5>
              Equipped with peristaltic pumps, this system ensures precise and
              consistent dosing of pH and nutrients into reservoirs. The doser
              can be conveniently positioned below the injection point, offering
              flexibility in installation. It operates quietly with a
              fan-cooling system for optimal performance.
              <ul className="mt-3">
                <li>Manual dosing functionality for tubing priming</li>
                <li>
                  Compact M3 and M4 sizes ideal for space-limited setups, with a
                  120ml/min flow rate
                </li>
                <li>
                  4 meters/13 feet of acid-/alkaline-resistant tubing for safe
                  pH dosing, plus 3x 4 meters/13 feet of food-grade tubing for
                  nutrient solutions
                </li>
                <li>Opaque tubing prevents algae buildup</li>
                <li>
                  Replaceable peristaltic pumps and tubing for long-term use
                </li>
                <li>
                  Durable, wall-mounted design ensures lasting performance
                </li>
                <li>
                  Expandable multi-part nutrient dosing system, supporting up to
                  12 pumps (Only M series can be linked together; L3 can only
                  link to other L3s)
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_PeriPod_M4;
