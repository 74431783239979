import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.webp";
import CardImg2 from "../../Assets/step2.webp";
import CardImg3 from "../../Assets/step3.webp";
import CardImg4 from "../../Assets/step4.webp";
import Enquiry from "../../Components/Enquiry";
import Accordion from "react-bootstrap/Accordion";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import fanpadFlatbed from "../../Assets/gallery/flatbed.webp";
function fanbadFlatbed() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-3">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Fan & Pad Flatbed
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                Fan & Pad Flatbed
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="m-3 mb-2">
            <h6 className="text-green">Fan & Pad Flatbed</h6>
            <h2 className="">
              Fan & Pad Poly House with Flat Bed N.F.T. System
            </h2>
            {/* <p className='text-muted m-2'>Fanpad flatbed is outdoor hydroponic farm setup. The outdoor hydroponics farm method is a type of controlled environment agriculture which allows you to grow various type of leafy greens and vine crops much densely then any other outdoor method, where you can save water use by up to 90%, requires 80 to 95 percent less area, and produce all year round.</p>
                        <p className='text-muted m-2'>
                            Royal Green House is a most recognised brand in the Indian Hydroponics Industry. We install and set up Hydroponics Project Development that allows you to avail the benefits of a completely automated farm with year round production.
                        </p> */}
            <Row>
              <Col lg={6} className="p-3 text-justify">
                <p className="text-muted ">
                  Poly House with fan and pad controlled. In this system,
                  Cooling pads are mounted in one end wall or sidewall of the
                  Polyhouse. They are supplied with water from a pipe above the
                  pads and excess water is collected in a gutter at the bottom.
                  Air drawn through the wet pads by slow axial fans mounted in
                  the opposite end wall or sidewall is saturated and cools the
                  greenhouse.Poly House with fan and pad controlled is used in
                  those systems where high efficiency cooling is required. It
                  can be used for many different cooling purposes but is
                  particularly suitable for cooling of greenhouses where higher
                  air velocity is required.{" "}
                </p>
                <p className="text-muted ">
                  Poly House with Cellulose Pads to acquire proper CFM of air
                  movement per sq.ft of area will be provided. Even water
                  delivery through distribution pipe will be ensured. Slow water
                  contamination and efficient water flow, to achieve temperature
                  10-12oC +2 oC below ambient temperature @ 45% RH.Evaporative
                  cooling, which uses the heat in the air to evaporate water
                  from plants and other wetted surfaces can be used to cool the
                  greenhouse as much as 10 to 12oC below the outside
                  temperature. Although evaporative cooling is most effective in
                  dryer climates, such as suitable for North, Central and west
                  part of India.{" "}
                </p>
                <p className="text-muted ">
                  The heat that is needed for the evaporation is taken from the
                  air itself. The air that leaves the pad is therefore cooled
                  and humidified simultaneously without any external energy
                  supply for the evaporation process. This type of Green House
                  is suitable for the Secondary Hardening of Banana, Vegetable
                  seedling & growing Strawberry cultvation etc.{" "}
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img
                  src={fanpadFlatbed}
                  alt="fanpad flatbed"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </Col>
        </Row>{" "}
        <Row>
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and A frame system now we can
                      grow 70 percent more food in the same area.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow anywhere you want
                    </Card.Title>
                    <Card.Text>
                      We can now grow our food anywhere with help of just water
                      and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-hand-holding-droplet text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">Less water</Card.Title>
                    <Card.Text>
                      Now grow crops on 90% less water compared to field crops.
                      This farming is possible at any location even in water
                      scared zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-bolt text-green  fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High value cash crops
                    </Card.Title>
                    <Card.Text>
                      Not only our daily crops but we can grow super-crops or
                      very high value cash crops with controlled environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          {/* second cards */}
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1} />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className=" text-white p-1">
                  We help you identify a site location to set up our hydroponic
                  project. We consider locality, levelling of land, examine
                  electricity and water also gather basic information of
                  surrounding that can help us test feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2} />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once financial feasibility is analysed we start working on the
                  Construction of the Project. Based on site feasibility and
                  market research we choose between NVPH or Fan-Pad system and
                  also hydroponic system between NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3} />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management{" "}
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once construction is completed, we work on automating the farm
                  using IoT devices as well as integrating with farm management.
                  At this stage, we also start training your field team to make
                  sure we continuously grow high quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4} />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Markerting Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once our field team is ready and the complete farm is
                  automated, Our team of agronomists, sales and planning starts
                  working on the contract and set a selling mechanism for your
                  farm, with a schedule of crops.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Acordian */}
        <Row>
          <h4 className="m-2 text-center fw-bold">Fan & Pad Specification</h4>

          <Col lg={6}>
            <Accordion flush className="m-4 p-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>What is the type of system?</Accordion.Header>
                <Accordion.Body className="text-muted">
                  This is Exhaust Fan and Cellulose Padded Poly house System.
                  Where temperature is maintained by creating vacuum inside poly
                  house.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What design style is being used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We use ‘Tunnel Design’ style to create this poly house.
                  Butterfly design is proven design to withstand wind speed upto
                  65km/hr. Also Maintenance requirement for butterfly design is
                  lower compared to other designs.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Where can you construct this system?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We can construct this almost anywhere where we can find strong
                  base. Mostly Fan & Pad is installed on land, terrace, any hard
                  surface or concrete foundation.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  What is the bay size of fan pad?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We strictly follow bay size of 8m X 4m (Width of NVPH should
                  be 35% of the desired length) as it provides ample of
                  sunlight, air flow and humidity.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  What is the ridge height of structure?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We keep ridge height of playhouse at 5.5 meter.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>How ridge vent is planned? </Accordion.Header>
                <Accordion.Body className="text-muted">
                  In this butterfly design vents are top open.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>What is gutter height? </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Gutter is placed at 4.5meter.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  What gutter slope is maintained?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Gutter slope is maintained at 2%.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  What structure material is used?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Entire structure is made up of GI (Galvanised Iron) Material.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  What aerodynamics is considered for designing the structure?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  The structural design will be sound enough to withstand wind
                  speed minimum 120 km/hr and minimum load of 25kg/m2.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  What measures are considered to develop the structure?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Complete structure made of galvanized steel tubular pipes of
                  equivalent section conforming Indian Standards having wall
                  thickness 2mm.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  What thickness of columns is used?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  All columns are 76 mm outer diameter, with 2mm of thickness.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  What are trusses specification?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Bottom & top cord are of 60 mm outer diameter with 2 mm
                  thickness.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  What trusses member & Purlin specification used?{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Trusses members and purlin are of 48mm and 42 mm outer
                  diameter with 2mm thickness.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="14">
                <Accordion.Header>
                  Which fans are used to achieve temperature drop?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  50″ Exhaust fans (6 SS blades design) with AC motor 1.5HP.
                  21000 CFM, with Auto opening shutters damper type. 415V, 50Hz.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="15">
                <Accordion.Header>
                  What are the specifications of cooling pads?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Cellulose Cooling pad 4inch thick & 5ft height. Single pad
                  containing area of 10sqFt.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="16">
                <Accordion.Header>
                  What are the specifications of submersible pump for pads?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Open well submersible 1HP 3 PHASE with available onsite
                  warranty.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="17">
                <Accordion.Header>
                  Which panel board is used to control fans?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Electric panel board for exhaust fan, ACF, and fogger.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <Col lg={6}>
            <Accordion flush className="m-4 p-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What specifications are followed for purlin members?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Purline members are of 33mm and 25 mm with 2mm thickness.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What thickness specifications are used for foundations?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  GI Pipes of minimum 60 mm OD or more to Foundation depth of 75
                  cm or more depending upon soil type and prevailing wind
                  condition, grouted with cement concrete mixture of 1:2:4 using
                  telescopic insertion of column.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What foundation accessories are considered?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  For foundation all nuts & bolts will be of high tensile
                  strength and galvanized materials.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  How entrance room & door are designed?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  One entrance room of size 4m x 3m x 2m (L x W x H) will be
                  provided and covered with 200 micron UV stabilized transparent
                  plastic film.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  What are the size and specification of doors.{" "}
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Doors of size 2m width & 2.5m height double leaf made in
                  plastic/FRP sheets mounted in a suitable strong frame.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  What cladding material is used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  UV stabilized 200 micron transparent plastics films.
                  Conforming Indian Standards (IS 15827: 2009), multilayered,
                  anti-drip, anti-fog, anti-sulphur, diffused, clear and having
                  minimum 85% level of light transmittance.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  What measures are used for fixing of cladding material?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  All ends/joints of plastic film will be fixed with a two way
                  aluminium/GI Profile with suitable locking arrangement along
                  with curtain top.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  What quality of spring insert are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Zigzag high carbon steel with spring action wire of 2-3 mm
                  diameter is inserted to fix the sheet into Aluminium Profile
                  or GI Profile.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  What curtains and Insect screen are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Roll up UV stabilized 200 micron transparent plastic film as
                  curtains will be provided up 2.5 to 3.0 m height on one side
                  with manual opening and closing of curtains. 40 mesh nylon
                  Insect proof/ mono mono nets (UV stabilized) of equivalent
                  size will be fixed Inside the curtains.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  What quality of shade net is used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Anti UV stabilized 50% shading net with manually operated
                  mechanism for expanding and retracting. Size of the net will
                  be equal to the floor area of the greenhouse.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  How 4-way foggers assembly is used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We use 65 Micron, LPD fitted, High pressure 4-way foggers with
                  lateral piping and PVC headers fittings. High pressure 5.5 KG
                  submersible pump with pressure gauge, Air release valve is
                  used. sand filter etc.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  What quality of vent opener are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  All vent openers are made up of brass, Pushback auto locking
                  with gear vent opener on all sides are used. Totally 4 number
                  of openers are used.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  What filtering mechanism is used for water filtration?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  We use sand filters to filter the water in system to help out
                  filter coco peat or other irregular substances.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  What kind of GI wires are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Galvanised GI wire are used for the rope ways.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="14">
                <Accordion.Header>
                  Which filter flush system is used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Leading kit with pipeline upto frame, bypass, filter flush
                  system, etc.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="15">
                <Accordion.Header>
                  Which cooling pad frame is used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Frame for the above cooling pads with top water distribution
                  and bottom gutter, Aluminium made.{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="16">
                <Accordion.Header>
                  How temperature and humidity is controlled?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Microprocessor based Temperature and Humidity Controller.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="17">
                <Accordion.Header>
                  Which air circulation fans are used?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Air circulation fans, SS Blade, 400MM / 500MM, Aluminium
                  Powder Coated.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="18">
                <Accordion.Header>
                  How shade net closing and opening pulley is implemented?
                </Accordion.Header>
                <Accordion.Body className="text-muted">
                  Above air circulation fan shade net closing and opening pulley
                  installation.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}

export default fanbadFlatbed;
