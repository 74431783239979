import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCart } from "../../Contexts/CartContext";
import "../../App.css";

import AutogrowEnviroSensor from "../../Assets/Autogrow EnviroSensor IntelliClimate CO2 Sensor.webp";
import AutogrowIntelliClimateKit from "../../Assets/Autogrow IntelliClimate Kit.webp";
import BluelabExternalLockout from "../../Assets/Bluelab External Lockout and Alarm Box.webp";
import BluelabGuardianMonitor from "../../Assets/Bluelab Guardian Monitor Inline Wi-Fi.webp";
import BluelabGuardianMonitorWiFi from "../../Assets/Bluelab Guardian Monitor Wi-Fi.webp";
import BluelabIntelliDoseControllerKit from "../../Assets/Bluelab IntelliDose Controller Kit.webp";
import BluelabIntelliLink from "../../Assets/Bluelab IntelliLink.webp";
import BluelabPeriPodL3 from "../../Assets/Bluelab PeriPod L3.webp";
import BluelabPeriPodM3 from "../../Assets/Bluelab PeriPod M3.webp";
import BluelabpHControllerWiFi from "../../Assets/Bluelab pH Controller Wi-Fi.webp";
import BluelabpHProbe5M from "../../Assets/Bluelab pH Probe 5M.webp";
import BluelabpHProbe from "../../Assets/Bluelab pH Probe.webp";
import BluelabPowerPod from "../../Assets/Bluelab PowerPod.webp";
import BluelabProControllerConductivityProbe from "../../Assets/Bluelab Pro Controller Conductivity Probe.webp";
import BluelabProControllerWiFi from "../../Assets/Bluelab Pro Controller Wi-Fi.webp";
import BluelabSampleTubeandFittings from "../../Assets/Bluelab Sample Tube and Fittings.webp";
import LargePumpCassetteonlysuitableforL3Peripods1200mls from "../../Assets/Bluelab-Large-Cassette-Only-1200ml-suitable_PUMPCASS-L_1.webp";
import BluelabPeriPodM4 from "../../Assets/Bluelab-Peripod-Peristaltic-Pump-M4_PERIPODM4_1.webp";
import PumpandCassettereplacementforPeripodL31200mls from "../../Assets/Bluelab-Replacement-Large-Pump-Cassette-1200ml-suitable_PUMPCONT-L_1.webp";
import PumpandCassettereplacementforPeripodM3M4100mls from "../../Assets/Bluelab-Replacement-Medium-Pump-and-Cassette-100-ml-suitable_PUMPCONT-M_1.webp";
import PumpandCassettereplacementforpHController10mls from "../../Assets/Pump and Cassette replacement for pH Controller - 10mls.webp";
import ReplacementpHControllerTubing from "../../Assets/Replacement pH Controller Tubing (13ft  4m).webp";
import ReplacementPumpCassettonlysuitableforpHControllerorM3M4Peripods from "../../Assets/Replacement Pump Cassette - only suitable for pH Controller or M3 or M4 Peripods.webp";

import CartModal from "../../Components/CartModal";

function Automation_Products_Monitors() {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const products = [
    {
      id: 6,
      name: "Autogrow EnviroSensor IntelliClimate CO2 Sensor",
      price: 74088,
      image: AutogrowEnviroSensor,
      link: "/Autogrow_EnviroSensor_IntelliClimate_CO2_Sensor",
      description: "Description for Product 1",
    },
    {
      id: 7,
      name: "Autogrow IntelliClimate Kit",
      price: 127008,
      image: AutogrowIntelliClimateKit,
      link: "/Autogrow_IntelliClimate_Kit",
      description: "Description for Product 2",
    },
    {
      id: 8,
      name: "Bluelab External Lockout and Alarm Box",
      price: 5628,
      image: BluelabExternalLockout,
      link: "/Bluelab_External_Lockout_and_Alarm_Box",
      description: "Description for Product 2",
    },
    {
      id: 9,
      name: "Bluelab Guardian Monitor Inline Wi-Fi",
      price: 47628,
      image: BluelabGuardianMonitor,
      link: "/Bluelab_Guardian_Monitor_Inline_WiFi",
      description: "Description for Product 2",
    },
    {
      id: 10,
      name: "Bluelab Guardian Monitor Wi-Fi",
      price: 32508,
      image: BluelabGuardianMonitorWiFi,
      link: "/Bluelab_Guardian_Monitor_WiFi",
      description: "Description for Product 2",
    },
    {
      id: 11,
      name: "Bluelab IntelliDose Controller Kit",
      price: 127008,
      image: BluelabIntelliDoseControllerKit,
      link: "/Bluelab_IntelliDose_Controller_Kit",
      description: "Description for Product 2",
    },
    {
      id: 12,
      name: "Bluelab IntelliLink",
      price: 14364,
      image: BluelabIntelliLink,
      link: "/Bluelab_IntelliLink",
      description: "Description for Product 2",
    },
    {
      id: 13,
      name: "Bluelab PeriPod L3",
      price: 211680,
      image: BluelabPeriPodL3,
      link: "/Bluelab_PeriPod_L3",
      description: "Description for Product 2",
    },
    {
      id: 14,
      name: "Bluelab PeriPod M3",
      price: 86940,
      image: BluelabPeriPodM3,
      link: "/Bluelab_PeriPod_M3",
      description: "Description for Product 2",
    },
    {
      id: 15,
      name: "Bluelab pH Controller Wi-Fi",
      price: 50400,
      image: BluelabpHControllerWiFi,
      link: "/Bluelab_pH_Controller_WiFi",
      description: "Description for Product 2",
    },
    {
      id: 16,
      name: "Bluelab pH Probe 5M",
      price: 7140,
      image: BluelabpHProbe5M,
      link: "/Bluelab_pH_Probe_5M",
      description: "Description for Product 2",
    },
    {
      id: 17,
      name: "Bluelab pH Probe",
      price: 6384,
      image: BluelabpHProbe,
      link: "/Bluelab_pH_Probe",
      description: "Description for Product 2",
    },
    {
      id: 18,
      name: "Bluelab PowerPod",
      price: 49140,
      image: BluelabPowerPod,
      link: "/Bluelab_PowerPod",
      description: "Description for Product 2",
    },
    {
      id: 19,
      name: "Bluelab Pro Controller Conductivity Probe",
      price: 4872,
      image: BluelabProControllerConductivityProbe,
      link: "/Bluelab_Pro_Controller_Conductivity_Probe",
      description: "Description for Product 2",
    },
    {
      id: 20,
      name: "Bluelab Pro Controller Wi-Fi",
      price: 105840,
      image: BluelabProControllerWiFi,
      link: "/Bluelab_Pro_Controller_WiFi",
      description: "Description for Product 2",
    },
    {
      id: 21,
      name: "Bluelab Sample Tube and Fittings",
      price: 18144,
      image: BluelabSampleTubeandFittings,
      link: "/Bluelab_Sample_Tube_and_Fittings",
      description: "Description for Product 2",
    },
    {
      id: 22,
      name: "Large Pump Cassette - only suitable for L3 Peripods -1200mls",
      price: 22260,
      image: LargePumpCassetteonlysuitableforL3Peripods1200mls,
      link: "/Large_Pump_Cassette",
      description: "Description for Product 2",
    },
    {
      id: 23,
      name: "Bluelab PeriPod M4",
      price: 117180,
      image: BluelabPeriPodM4,
      link: "/Bluelab_PeriPod_M4",
      description: "Description for Product 2",
    },
    {
      id: 24,
      name: "Pump and Cassette replacement for Peripod L3 - 1200mls",
      price: 35028,
      image: PumpandCassettereplacementforPeripodL31200mls,
      link: "/Pump_and_Cassette_L3",
      description: "Description for Product 2",
    },
    {
      id: 25,
      name: "Pump and Cassette replacement for Peripod M3/M4 – 120mls",
      price: 14616,
      image: PumpandCassettereplacementforPeripodM3M4100mls,
      link: "/Pump_and_Cassette_M3_M4",
      description: "Description for Product 2",
    },
    {
      id: 26,
      name: "Pump and Cassette replacement for pH Controller - 10mls",
      price: 10584,
      image: PumpandCassettereplacementforpHController10mls,
      link: "/Pump_and_Cassette_replacement_for_pH_Controller",
      description: "Description for Product 2",
    },
    {
      id: 27,
      name: "Replacement pH Controller Tubing (13ft / 4m)",
      price: 5712,
      image: ReplacementpHControllerTubing,
      link: "/Replacement_pH_Controller_Tubing",
      description: "Description for Product 2",
    },
    {
      id: 28,
      name: "Replacement Pump Cassette - only suitable for pH Controller or M3/M4 Peripods",
      price: 5712,
      image: ReplacementPumpCassettonlysuitableforpHControllerorM3M4Peripods,
      link: "/Replacement_Pump_Cassette",
      description: "Description for Product 2",
    },
  ];

  const itemsPerPage = 12;
  const totalPages = Math.ceil(products.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const currentProducts = products.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Container>
      <Row>
        {currentProducts.map((product) => (
          <Col key={product.id} xs={12} sm={6} md={3}>
            <Link
              to={product.link}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card className="product-card">
                <Card.Img
                  variant="top"
                  src={product.image}
                  alt={product.name}
                  className="product-image"
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    {product.name}
                  </Card.Title>
                  <Card.Text className="text-center">
                    Price: ₹{product.price}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </Container>
  );
}

export default Automation_Products_Monitors;
