import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory27 from "../../Assets/accessory27.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Plant_Support_Clip_for_vinecrops = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 73,
      name: "Plant Support Clip for Vine crops",
      price: 1.25,
      image: accessory27,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory27}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Plant Support Clip for Vine crops</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Plant Support Clip for Vine Crops is a crucial accessory
                  used to secure and train plants such as tomatoes, cucumbers,
                  and other vine crops in greenhouses and polyhouses. Designed
                  for optimal plant health and growth, these clips provide
                  gentle yet firm support, ensuring that plants grow straight
                  and remain well-supported throughout their lifecycle.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> High-Density
                      Plastic
                    </li>

                    <li>
                      <span className="fw-bold">Dimension:</span> 1.5 inches
                      (diameter)
                    </li>

                    <li>
                      <span className="fw-bold">Usage/Application:</span>
                      Supporting and training vine crops by securing plants to
                      wires or trellises
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 2-4 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    <span className="fw-bold">Gentle on Plants:</span> Designed
                    to support plants without causing damage or restriction to
                    growth.
                  </li>
                  <li>
                    <span className="fw-bold">
                      Quick and Easy Installation:
                    </span>
                    Simple clipping mechanism allows for fast attachment to
                    plant stems and support wires.
                  </li>
                  <li>
                    <span className="fw-bold">Reusable:</span> Made from durable
                    plastic that can be reused across multiple crop cycles.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">Why Choose RGCPL’s Plant Support Clip?</h5>
        <p className="text-justify">
          Royal Green House offers high-quality, durable plant support clips
          tailored specifically for vine crops. Made from strong yet gentle
          materials, our clips are designed for long-lasting use, making them a
          reliable choice for greenhouse growers. With our expertise in
          greenhouse accessories, you can trust Royal Green House for top-tier
          products that provide optimal plant support and growth.
        </p>
        <h5 className="mt-3">Key Benefits of Plant Support Clip:</h5>
        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Promotes Healthy Growth:</span> Provides
              essential support to keep plants upright, allowing for better air
              circulation and sun exposure.
            </li>
            <li>
              <span className="fw-bold">Durable and Reusable:</span>{" "}
              Long-lasting plastic ensures the clips can be reused season after
              season, making them cost-effective.
            </li>
            <li>
              <span className="fw-bold">Easy to Use:</span> The simple clip
              design makes attaching plants to support systems quick and
              efficient.
            </li>
          </ul>{" "}
        </p>

        <h5 className="mt-3">Applications of Plant Support Clip:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Used to secure and support vine crops such as tomatoes, cucumbers,
              and beans in greenhouse and polyhouse settings.
            </li>
            <li>
              Ideal for managing plant growth and training crops along trellises
              or support systems.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Plant_Support_Clip_for_vinecrops;
