import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCart } from "../../Contexts/CartContext";
import "../../App.css";

import BluelabSamplePot from "../../Assets/Bluelab Sample Pot.webp";
import BluelabProbeCareKitpHImg from '../../Assets/Bluelab Probe Care Kit - pH.webp';
import BluelabProbeCareKitpH from '../../Assets/Bluelab Probe Care Kit_ pH and Conductivity.webp';
import BluelabProbeCareKitConductivity from '../../Assets/Bluelab Probe Care Kit - Conductivity.webp';
import BluelabpHProbeKClStorageSolution from '../../Assets/Bluelab pH Probe KCl Storage Solution - 120 ml.webp';
import BluelabpHProbeKClStorageSol250 from '../../Assets/Bluelab pH Probe KCl Storage Solution_250 ml.webp';

import CartModal from "../../Components/CartModal";

function Automation_Products_Testing_and_Maintenance() {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  
  const products = [
    {
      id: 41,
      name: "Bluelab pH Probe KCl Storage Solution - 120 ml",
      price: 6804,
      image: BluelabpHProbeKClStorageSolution,
      link: "/Bluelab_pH_Probe_KCl_Storage_Solution",
      description: "Description for Product 1",
    },
    {
      id: 42,
      name: "Bluelab pH Probe KCl Storage Solution – 250 ml",
      price: 11340,
      image: BluelabpHProbeKClStorageSol250,
      link: "/Bluelab_pH_Probe_KCl_Storage_Solution_250ml",
      description: "Description for Product 2",
    },
    {
      id: 43,
      name: "Bluelab Probe Care Kit - Conductivity",
      price: 1344,
      image: BluelabProbeCareKitConductivity,
      link: "/Bluelab_Probe_Care_Kit_Conductivity",
      description: "Description for Product 2",
    },
    {
      id: 44,
      name: "Bluelab Probe Care Kit – pH and Conductivity",
      price: 2352,
      image: BluelabProbeCareKitpH,
      link: "/Bluelab_Probe_Care_Kit_pH_and_Conductivity",
      description: "Description for Product 2",
    },
    {
      id: 45,
      name: "Bluelab Probe Care Kit - pH",
      price: 1848,
      image: BluelabProbeCareKitpHImg,
      link: "/Bluelab_Probe_Care_Kit_pH",
      description: "Description for Product 2",
    },
    {
      id: 46,
      name: "Bluelab Sample Pot",
      price: 8484,
      image: BluelabSamplePot,
      link: "/Bluelab_Sample_Pot",
      description: "Description for Product 2",
    },
    
  ];

  const itemsPerPage = 12;
  const totalPages = Math.ceil(products.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const currentProducts = products.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Container>
      <Row>
        {currentProducts.map((product) => (
          <Col key={product.id} xs={12} sm={6} md={3}>
            <Link
              to={product.link}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card className="product-card">
                <Card.Img
                  variant="top"
                  src={product.image}
                  alt={product.name}
                  className="product-image"
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    {product.name}
                  </Card.Title>
                  <Card.Text className="text-center">
                    Price: ₹{product.price}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>

      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </Container>
  );
}

export default Automation_Products_Testing_and_Maintenance;
