import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory12 from "../../Assets/accessory12.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Galvanized_Iron_Channel_GI_Channel = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 58,
      name: "GI Profile",
      price: "100/Per 4 Meter",
      image: accessory12,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory12}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
            GI Profile
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  RGCPL GI Channels are key components for greenhouse and poly
                  house support systems, used primarily for securing poly sheets
                  and netting to the structure. Made from high-quality
                  galvanized iron, these channels provide strong and reliable
                  support while being resistant to rust and corrosion, making
                  them suitable for long-term outdoor use.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized Iron
                    </li>
                    <li>
                      <span className="fw-bold">Shape:</span> Rectangular
                    </li>
                    <li>
                      <span className="fw-bold">Thickness:</span> 4 mm
                    </li>
                    <li>
                      <span className="fw-bold">Length:</span> 10 Inches
                    </li>
                    <li>
                      <span className="fw-bold">Usage/Application:</span>{" "}
                      Greenhouse/Poly House Covering Support
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 2-3 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    Eco-friendly: Produced with environmentally friendly
                    processes.
                  </li>
                  <li>
                    High strength: Capable of supporting significant loads
                    without deformation.
                  </li>
                  <li>
                    Corrosion-resistant: Withstands harsh weather conditions
                    effectively.
                  </li>
                  <li>Structure: GI (Galvanized Iron)</li>
                  <li>Built Type: Structural Channel</li>
                  <li>Type: Greenhouse/Poly House Structural Support</li>
                  <li>Pattern: RGCPL</li>
                </ul>
              </Tab.Pane>
              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">
          Importance of Galvanized Iron Channel (GI Channel):
        </h5>
        <p className="text-justify">
          GI Channels play a pivotal role in securing the coverings of
          greenhouses and poly houses. Without these channels, poly sheets or
          netting can shift or become loose, compromising the integrity of the
          greenhouse. Channels ensure a tight, secure fit, reducing the need for
          frequent maintenance.
        </p>
        <h5 className="mt-3">
          Key Benefits of Galvanized Iron Channel (GI Channel):
        </h5>
        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Rust-proof:</span> Ensures the product
              can withstand rain, humidity, and other environmental factors.
            </li>
            <li>
              <span className="fw-bold">Easy to install:</span>Pre-cut lengths
              and standardized sizing make installation quick and efficient.
            </li>
            <li>
              <span className="fw-bold">Strong and durable:</span> Designed to
              support poly sheets and netting without bending or sagging over
              time.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Applications:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Greenhouse and poly house coverings: Used to fasten poly sheets or
              netting to the structure, ensuring a secure fit and preventing
              damage from wind or heavy rains.
            </li>
            <li>
              Can also be used in shade houses for similar support functions.
            </li>
          </ul>
        </p>
      </div>
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};
export default Galvanized_Iron_Channel_GI_Channel;
