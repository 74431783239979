import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import AutogrowIntelliClimateKit from "../../Assets/Autogrow IntelliClimate Kit.webp";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Autogrow_IntelliClimate_Kit = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 7,
      name: "Autogrow_IntelliClimate_Kit",
      price: 127008,
      image: AutogrowIntelliClimateKit,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={AutogrowIntelliClimateKit}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Autogrow IntelliClimate Kit</h2>
            <p className="product-price">₹{127008}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>
                Autogrow IntelliClimate: Complete Climate Control for Your Grow
                Room
              </h5>
              The Autogrow IntelliClimate is an all-in-one controller that
              automates every aspect of your grow room’s climate control. From
              regulating temperature and CO2 levels to managing lighting and
              humidity, this system is designed to simplify the process.
              <p className="mt-2">
                Tailored specifically for indoor growers, IntelliClimate offers
                unparalleled accuracy and efficiency, providing commercial-grade
                climate management for your growing environment. No other
                controller on the market offers this level of precision and
                control.
              </p>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Autogrow_IntelliClimate_Kit;
