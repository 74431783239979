import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BlbStarterpen from "../../Assets/Bluelab pH and Conductivity Starter Pack.webp";
import "../../App.css"; // Import your CSS file for styles
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Product2 = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 2,
      name: "Bluelab pH and Conductivity Starter Pack",
      price: 18900,
      image: BlbStarterpen,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BlbStarterpen}
                  alt="Product 2"
                  className="d-block w-100"
                />
              </div>
              {/* <div className="carousel-item">
                <img src={DwcImage} alt="Product 2" className="d-block w-100" />
              </div>
              <div className="carousel-item">
                <img src={DwcImage} alt="Product 2" className="d-block w-100" />
              </div> */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Bluelab pH and Conductivity Starter Pack
            </h2>{" "}
            {/* Update the product name */}
            <p className="product-price">₹{18900}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3">
              <h3>
                Essential tools for measuring pH, conductivity, and temperature
              </h3>
              <ul>
                <li>
                  Convenient, compact handheld design for easy portability
                </li>
                <li>
                  Easy-to-read, bright backlit display for clear visibility
                </li>
                <li>Waterproof and floats, ideal for nutrient reservoirs</li>
              </ul>
              <h4>Bluelab Conductivity Pen:</h4>
              <ul>
                <li>
                  Accurately measures conductivity in EC, ppm 500 (TDS), and ppm
                  700
                </li>
                <li>Displays temperature in both °C and °F</li>
                <li>Pre-calibrated at the factory, ready for immediate use</li>
              </ul>
              <h4>Bluelab pH Pen:</h4>
              <ul>
                <li>Monitors both pH and temperature levels</li>
                <li>
                  Equipped with a double-junction probe and straightforward
                  2-point calibration
                </li>
                <li>Includes a 30-day reminder for calibration adjustments</li>
              </ul>
            </p>{" "}
            {/* Update the product description */}
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Product2;
