import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BlbOnepen from "../../Assets/Bluelab OnePen.webp";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Product1 = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 1,
      name: "Bluelab OnePen",
      price: 18816,
      image: BlbOnepen,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BlbOnepen}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab OnePen</h2>
            <p className="product-price">₹{18816}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3">
              <h3>An advanced solution for growers of all expertise levels</h3>
              This 3-in-1 device accurately measures pH, Conductivity (EC/PPM),
              and Temperature, helping to maximize nutrient absorption. Connect
              via Bluetooth to the Edenic app for real-time monitoring, easy
              data sharing, and in-depth management.
              <h4 className="text-justify mt-4">Notable features:</h4>
              <ul>
                <li>Ergonomic design with a backlit, high-contrast display</li>
                <li>
                  Replaceable probe with a health status indicator Provides
                  highly precise pH measurements with 0.01 resolution
                </li>
                <li>Displays pH and conductivity (EC) simultaneously</li>
                <li>
                  Measures conductivity in EC, CF, PPM 500 (TDS), or PPM 700
                </li>
                <li>Offers temperature readings in both ˚C and ˚F</li>
                <li>Built with an IP68 rating for dust and water resistance</li>
              </ul>
              <h4>When linked with the Edenic app:</h4>
              <ul>
                <li>Access measurements remotely through the app or web</li>
                <li>Share real-time data and get software updates instantly</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Product1;
