import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabGuardianMonitorWiFi from "../../Assets/Bluelab Guardian Monitor Wi-Fi.webp";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_Guardian_Monitor_WiFi = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 10,
      name: "Bluelab Guardian Monitor Wi-Fi",
      price: 32508,
      image: BluelabGuardianMonitorWiFi,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabGuardianMonitorWiFi}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab Guardian Monitor Wi-Fi</h2>
            <p className="product-price">₹{32508}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              With the Wi-Fi-enabled Bluelab Guardian Monitor, you can
              effortlessly monitor your reservoir’s pH, conductivity (EC), and
              temperature while receiving instant alerts on your phone. Whether
              you’re nearby or away, the Guardian Monitor ensures your grow
              environment remains optimized for healthier plants.
              <ul className="mt-2">
                <li>
                  <span className="fw-bold">Built-in Wi-Fi</span> (can be
                  disabled) for remote access.
                </li>
                <li>
                  <span className="fw-bold">High/low alarms</span> for pH,
                  temperature, and EC levels.
                </li>
                <li>
                  <span className="fw-bold">Non-volatile memory</span> retains
                  settings after power loss.
                </li>
                <li>
                  <span className="fw-bold">Plant-safe green LEDs</span> with
                  adjustable brightness for easy reading.
                </li>
                <li>
                  <span className="fw-bold">2-meter probe cables</span> offer
                  flexibility in setup.
                </li>
                <li>
                  <span className="fw-bold">110-240 VAC</span> mains powered
                  with interchangeable plugs.
                </li>
                <li>
                  <span className="fw-bold">Replaceable pH probe</span> with BNC
                  connection for long-term use.
                </li>
              </ul>
              <h5 className="mt-2">Connect with the Edenic by Bluelab App for:</h5>
              <ul>
                <li>Instant alerts sent directly to your phone.</li>
                <li>Remote monitoring and control of your system.</li>
                <li>Historical data reporting for informed decision-making.</li>
              </ul>
              And now, the Bluelab Guardian Monitor Wi-Fi is available,
              offering continuous inline monitoring to ensure your nutrient
              delivery system is always running efficiently!
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_Guardian_Monitor_WiFi;
