import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory19 from "../../Assets/accessory19.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Greenhouse_Base_Plate_Corner_Support_Plate = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 65,
      name: "Greenhouse Base Plate (Corner Support Plate)",
      price: 250,
      image: accessory19,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory19}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Greenhouse Base Plate (Corner Support Plate)
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Greenhouse Base Plate or Corner Support Plate is a vital
                  part of the greenhouse foundation structure. It provides
                  stable support to the corner or junction areas of the
                  greenhouse, ensuring durability and strength. It also ensures
                  that the vertical poles or pipes are anchored securely to the
                  ground or base.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized Iron
                      (GI)
                    </li>
                    <li>
                      <span className="fw-bold">Size:</span> Customized as per
                      pipe diameter and requirements
                    </li>
                    <li>
                      <span className="fw-bold">Design:</span> Flat with
                      multiple drilled holes for easy installation
                    </li>
                    <li>
                      <span className="fw-bold">Usage/Applications:</span> Used
                      for fixing poles or frames to the ground
                    </li>

                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 2-3 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    Corrosion-resistant: Zinc-coated for long-term protection
                    against rust.
                  </li>
                  <li>
                    High load-bearing capacity: Provides sturdy support for
                    greenhouse frames.
                  </li>
                  <li>
                    Easy to Install: Pre-drilled holes make it easy to secure.
                  </li>
                  <li>
                    Durable Design: Built to last in outdoor environments.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">
          Importance of Greenhouse Base Plate (Corner Support Plate):
        </h5>
        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Foundation Stability:</span> This
              accessory is crucial for providing a stable foundation to
              greenhouses. Without a strong base plate, the entire structure may
              lose its integrity, especially in adverse weather conditions.
            </li>
            <li>
              <span className="fw-bold">Wind Resistance:</span> Ensures that the
              greenhouse remains grounded during high winds, which is vital for
              maintaining the overall safety of the structure.
            </li>
            <li>
              <span className="fw-bold">Even Weight Distribution:</span> Helps
              in evenly distributing the weight of the greenhouse frame,
              preventing sagging or uneven stress on the structure.
            </li>
          </ul>{" "}
        </p>
        <h5 className="mt-3">
          Key Benefits of Greenhouse Base Plate (Corner Support Plate):
        </h5>

        <p className="text-justify">
          <ul>
            <li>
              Provides stable base support for polyhouses and greenhouses.
            </li>
            <li>
              Adds structural integrity to the foundation of the framework.
            </li>
            <li>Simple design for easy fixing and long-lasting performance.</li>
          </ul>
        </p>
        <h5 className="mt-3">
          Applications of Greenhouse Base Plate (Corner Support Plate):
        </h5>
        <p className="text-justify">
          <ul>
            <li>
              Used for supporting the base structure in greenhouses and
              polyhouses.
            </li>
            <li>
              Ideal for tunnel, net house, and other agricultural constructions.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Greenhouse_Base_Plate_Corner_Support_Plate;
