import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory32 from "../../Assets/accessory32.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Poly_House_Gutter_Plate = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 78,
      name: "Poly House Gutter Plate by RGCPL – Manufactured in India",
      price: 450,
      image: accessory32,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory32}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Poly House Gutter Plate by RGCPL – Manufactured in India
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  RGCPL is a leading manufacturer of Poly House Gutter Plates in
                  India. We specialize in providing high-quality, eco-friendly,
                  and durable gutter plates designed for efficient water
                  drainage in greenhouse and poly house structures. Manufactured
                  in our in-house facility in India, these gutter plates are
                  vital for maintaining an optimal environment for agricultural
                  projects by preventing water accumulation and potential
                  structural damage.
                  <h6 className="mt-3">Manufactured By: </h6>RGCPL (In-House
                  Gutter Manufacturing Unit – India Only)
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Structure" className="custom-tab">
                  Structure
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>Material: Galvanized Iron (GI) </li>
                    <li>Color: White </li>
                    <li>Height: 8 Inches</li>
                    <li>Length: 10 Inches </li>
                    <li>Thickness: 4 mm </li>
                    <li>Shape: Rectangular </li>
                    <li>Usage/Application: Agriculture </li>
                    <li>Brand: RGCPL</li>
                    <li>Durability: 2-3 Years</li>
                    <li>Application: Greenhouse/Poly House</li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>Eco-friendly </li>
                  <li>Easy to use </li>
                  <li>Durable and weather-resistant</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="Structure">
                <h4>Structure</h4>
                <ul>
                  <li>Structure: GI (Galvanized Iron) </li>
                  <li>Built Type: GI Structure </li>
                  <li>
                    Type: All Types of Greenhouse and Poly House Structures{" "}
                  </li>
                  <li>Pattern: RGCPL</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>Item Code: RGCPL-0001</li>
                    <li>Delivery Time: 7-8 Days </li>
                    <li>Port of Dispatch: Maharashtra, India </li>
                    <li>Production Capacity: 500 Pieces Per Day </li>
                    <li>Packaging Details: Large Bags </li>
                    
                    <li>Payment Terms: Advance Payment </li>
                    <li>Payment Mode: RTGS / NEFT / IMPS</li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">Why Choose RGCPL for Poly House Gutter Plates?</h5>
        <p className="mt-1 text-justify">
          RGCPL, with an in-house gutter manufacturing unit based solely in
          India, is a trusted provider of poly house gutter solutions. Our
          expertise and control over the production process ensure that every
          piece is made to meet the highest quality standards. By manufacturing
          locally, we can offer competitive pricing and faster delivery to meet
          your project timelines.
        </p>
        <h5 className="mt-3">Importance of Poly House Gutter Plates</h5>
        <p className="text-justify">
          Poly House Gutter Plates are essential in any poly house or greenhouse
          structure for proper water management. The primary function of these
          plates is to secure joints between roof sections and facilitate
          efficient water drainage, preventing roof leaks, water accumulation,
          and structural damage.{" "}
        </p>
        <p className="text-justify">
          In poly house structures, water runoff management is critical for
          maintaining the right environmental conditions for plant growth.
          RGCPL’s gutter plates are designed to withstand tough weather
          conditions, ensuring the longevity and stability of your structure.
        </p>
        <h5 className="mt-3">Key Benefits of RGCPL’s Gutter Plates:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Water Drainage Efficiency: Properly installed gutter plates direct
              rainwater or irrigation water away from the roof, preventing leaks
              and moisture build-up.{" "}
            </li>
            <li>
              Weather-Resistant: Made from high-quality galvanized iron, our
              gutter plates resist corrosion and can endure harsh weather
              conditions.{" "}
            </li>
            <li>
              Durability: With a lifespan of 2-3 years, our gutter plates offer
              reliable long-term performance.{" "}
            </li>
            <li>
              Eco-Friendly: RGCPL gutter plates are designed with
              environmentally friendly materials, making them an ideal choice
              for sustainable agriculture practices.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">
          Types of Greenhouse Gutter Plates Available from RGCPL:
        </h5>
        <p className="text-justify">
          RGCPL offers a variety of gutter plates tailored to different
          greenhouse and poly house designs and needs:
          <ol>
            <li>
              PVC Gutter Plates: Lightweight, UV-resistant, and corrosion-free.
              Ideal for smaller structures.{" "}
            </li>
            <li>
              Aluminum Gutter Plates: Strong and durable, commonly used for
              larger greenhouses due to their excellent resistance to rust.{" "}
            </li>
            <li>
              Galvanized Steel Gutter Plates: Heavy-duty and perfect for
              industrial-sized greenhouses.{" "}
            </li>
            <li>
              Polycarbonate Gutter Plates: Allows light to pass through while
              ensuring efficient water drainage.
            </li>
            <li>
              Fiberglass Gutter Plates: High resistance to chemicals and
              moisture, ideal for specific agricultural environments.
            </li>
            <li>
              Hybrid Gutter Plates: Combination of materials like plastic and
              aluminum to balance cost and durability.
            </li>
          </ol>
        </p>
        <h5 className="mt-3">
          Installation Methods and Techniques for Gutter Plates:
        </h5>
        <p className="text-justify">
          Connecting RGCPL Poly House Gutter Plates is simple and efficient
          using various methods that ensure a secure and leak-free fit:
          <ul>
            <li>
              Overlapping Joints: Secure overlapping plates with screws for a
              tight connection.{" "}
            </li>
            <li>
              Snap-Fit Systems: Some gutter plates come with snap-fit mechanisms
              that eliminate the need for additional fasteners.{" "}
            </li>
            <li>
              Sealant or Adhesive: Waterproof sealants or adhesives can be
              applied for enhanced leak prevention.{" "}
            </li>
            <li>
              Brackets or Hangers: Added stability through brackets or hangers,
              ensuring the gutters remain aligned and strong.{" "}
            </li>
            <li>
              Screws and Bolts: Traditional method using screws for a reliable
              and sturdy connection.{" "}
            </li>
            <li>
              Gutter Connectors: Specialized connectors are available to fit
              specific gutter profiles, making installation easy and effective.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Gutter Manufacturing by RGCPL:</h5>
        <p className="text-justify">
          RGCPL prides itself on being a leading manufacturer of poly house
          gutter plates in India. As we manufacture our products in-house, we
          maintain strict quality control and offer customization options to
          meet the unique needs of our clients. Whether you are building a large
          commercial greenhouse or a smaller agricultural poly house, our gutter
          plates are designed for performance and longevity.{" "}
        </p>
        <p className="text-justify">
          By choosing RGCPL, you are not only supporting local Indian
          manufacturing but also ensuring that you receive top-quality products
          at competitive prices with fast and reliable delivery across the
          country.
          <ul>
            <li>Poly House Gutter Plate Manufacturer in India </li>
            <li>Greenhouse Gutter Plates </li>
            <li>Galvanized Iron Gutter Plates for Greenhouses </li>
            <li>RGCPL Poly House Accessories</li>
            <li>Gutter Plates for Poly House Structures</li>
            <li>Greenhouse Drainage Solutions </li>
            <li>Durable Gutter Plates India </li>
            For inquiries and orders, contact RGCPL today and ensure your poly
            house structure is equipped with the best gutter system for
            long-term success!
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Poly_House_Gutter_Plate;
