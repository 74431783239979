import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabComboMeter from "../../Assets/Bluelab Combo Meter.webp";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_Combo_Meter = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 32,
      name: "Bluelab Combo Meter",
      price: 24948,
      image: BluelabComboMeter,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabComboMeter}
                  alt="bluelab combo meter"
                  className="d-block w-100"
                />
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab Combo Meter</h2>
            <p className="product-price">₹{24948}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>Portable 3-in-1 Handheld Meter for Nutrient Solutions</h5>
              This versatile handheld meter is your go-to tool for measuring{" "}
              <span className="fw-bold">
                pH, conductivity (EC), and temperature
              </span>{" "}
              in nutrient solutions. Designed with simplicity in mind, it offers
              intuitive operation, easy cleaning, and straightforward
              calibration. Whether you're working in bright or low-light
              conditions, the backlit display ensures that all measurements are
              clearly visible.
              <h5 className="mt-3">Key features include:</h5>
              <ul>
                <li>
                  <span className="fw-bold">
                    Customizable conductivity settings:
                  </span>{" "}
                  Switch between EC, CF, PPM 500 (TDS), or PPM 700, depending on
                  your preference.
                </li>
                <li>
                  <span className="fw-bold">Selectable temperature units:</span>{" "}
                  Easily toggle between °C and °F.
                </li>
                <li>
                  <span className="fw-bold">
                    Combined Conductivity/Temperature Probe:
                  </span>{" "}
                  Ensures efficient and quick measurement.
                </li>
              </ul>
              For added convenience, the meter is equipped with Automatic
              Temperature Compensation (ATC), ensuring your readings remain
              accurate regardless of the ambient temperature. The replaceable
              double-junction Bluelab pH Probe, featuring a durable BNC
              connection, offers an extended lifespan for continuous use.
              <h5 className="mt-3">Other benefits include:</h5>
              <ul>
                <li>
                  <span className="fw-bold">2-point calibration system</span>{" "}
                  for maintaining precision.
                </li>
                <li>
                  <span className="fw-bold">2-meter (6.6-foot) cable</span>,
                  perfect for reaching difficult spots in your grow space.
                </li>
                <li>
                  Powered by{" "}
                  <span className="fw-bold">two standard AAA batteries</span>{" "}
                  (included).
                </li>
              </ul>
              To ensure reliability, the meter comes with a 5-year limited
              warranty, giving you peace of mind as you monitor your nutrient
              solutions with ease.
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_Combo_Meter;
