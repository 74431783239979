import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import style from "./changesitedata.module.css";
import { useNavigate } from "react-router-dom";
import "../../App.css";

function Dashboard(props) {
  const navigate = useNavigate();
  const [galleryData, setGalleryData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to track submission status

  useEffect(() => {
    if (props.isLogin === false) {
      navigate("/login", { replace: true });
    }
  }, [props.isLogin]);

  // Fetching initial data for gallery and reviews
  useEffect(() => {
    setGalleryData(props.galleryData);
  }, [props.galleryData]);

  useEffect(() => {
    setReviewData(props.reviewData);
  }, [props.reviewData]);

  // Fetching new order data
  const fetchOrderData = async () => {
    try {
      const response = await fetch(
        "https://royalgreenhouse.co.in/backend/fetchOrders.php"
      );
      const data = await response.json();

      if (data.status === "success") {
        setOrderData((prevOrderData) => {
          // Check for new orders that are not already in the state
          const newOrders = data.orders.filter(
            (newOrder) =>
              !prevOrderData.some(
                (existingOrder) => existingOrder.id === newOrder.id
              )
          );
          // Concatenate previous orders with new ones at the bottom
          return [...prevOrderData, ...newOrders]; // Add new orders to the bottom
        });
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrderData(); // Fetch orders on component mount
    const intervalId = setInterval(fetchOrderData, 30000); // Fetch new orders every 30 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  // Base64 conversion function
  const Base64 = (file) => {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result);
      };
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  };

  // Adding and handling review photos
  const addReviewPhoto = () => {
    const arr = [...reviewData];
    arr.push({ add: true, delete: false });
    setReviewData(arr);
  };

  const handleChangeReview = async (e, ind) => {
    const fileValue = e.target.value;
    const arr = [...reviewData];
    arr[ind] = { ...arr[ind], [e.target.name]: fileValue };
    setReviewData(arr);
  };

  // Adding and handling gallery photos
  const addGalleryPhoto = () => {
    const arr = [...galleryData];
    arr.push({ add: true, delete: false });
    setGalleryData(arr);
  };

  const handleChangeGallery = async (e, ind) => {
    const fileValue = await Base64(e.target.files[0]);
    const arr = [...galleryData];
    arr[ind] = { ...arr[ind], [e.target.name]: fileValue };
    setGalleryData(arr);
  };

  // Submit function for forms
  const handleSubmit = async (event, data, formName, index, del) => {
    event.preventDefault();

    if (isSubmitting) {
      console.log("Submission in progress, please wait.");
      return; // Prevent double submission
    }

    setIsSubmitting(true); // Set submission state to true

    data.formName = formName;
    if (del) {
      data.delete = true;
    }

    try {
      const response = await fetch("https://royalgreenhouse.co.in/crud.php", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json", // Ensure proper content type
        },
      });

      const result = await response.text();
      if (result === "success") {
        console.log("Data submitted successfully.");

        // Update the respective data arrays
        if (data.formName === "gallery") {
          const arr = [...galleryData];
          arr[index] = { ...arr[index], show: true };
          if (arr[index].delete) {
            arr.splice(index, 1);
          }
          setGalleryData(arr);
        } else if (data.formName === "review") {
          const arr = [...reviewData];
          arr[index] = { ...arr[index], show: true };
          if (arr[index].delete) {
            arr.splice(index, 1);
          }
          setReviewData(arr);
        }
      } else {
        console.error("Error from server:", result);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setIsSubmitting(false); // Reset submission state
    }
  };

  return (
    <div>
      <Tabs
        defaultActiveKey="gallery"
        id="uncontrolled-tab-example"
        className="mb-3 pt-3"
      >
        <Tab eventKey="gallery" title="Gallery">
          <div className={style.container}>
            {galleryData.map((elem, ind) => (
              <form
                key={ind}
                className={style.contact}
                encType="multipart/form-data"
                onSubmit={(e) => handleSubmit(e, elem, "gallery", ind)}
              >
                <h3>Gallery Data</h3>
                <h4>Change gallery data</h4>
                <span
                  type="button"
                  onClick={(e) =>
                    window.confirm("Are you sure you want to delete this?")
                      ? handleSubmit(e, elem, "gallery", ind, true)
                      : ""
                  }
                  className="my-2 p-2 rounded-3 text-light bg-danger"
                >
                  Delete Image
                </span>
                <fieldset>
                  <span></span>
                  <img
                    className="img-fluid"
                    src={elem.image || ""}
                    alt="hydroponics"
                  />
                </fieldset>
                <fieldset>
                  <span>Change Image</span>
                  <input
                    name="image"
                    type="file"
                    onChange={(e) => handleChangeGallery(e, ind)}
                  />
                </fieldset>
                <fieldset>
                  <button name="submit" type="submit" disabled={isSubmitting}>
                    Send
                  </button>
                </fieldset>
                {elem.show && (
                  <div className={style.success}>
                    <i className="fa fa-check"></i>
                    Form saved successfully!
                  </div>
                )}
              </form>
            ))}
            <div onClick={addGalleryPhoto} className={style.addBtn}>
              ADD
            </div>
          </div>
        </Tab>

        {/* For review */}
        <Tab eventKey="review" title="Review">
          <div className={style.container}>
            {reviewData.map((elem, ind) => (
              <form
                key={ind}
                className={style.contact}
                encType="multipart/form-data"
                onSubmit={(e) => handleSubmit(e, elem, "review", ind)}
              >
                <h3>Review Data</h3>
                <h4>Change Review data</h4>
                <span
                  type="button"
                  onClick={(e) =>
                    window.confirm("Are you sure you want to delete this?")
                      ? handleSubmit(e, elem, "review", ind, true)
                      : ""
                  }
                  className="my-2 p-2 rounded-3 text-light bg-danger"
                >
                  Delete Review
                </span>
                <fieldset>
                  <span>Change Review Image</span>
                  <img
                    className="img-fluid"
                    src={elem.image || ""}
                    alt="hydroponic"
                  />
                  <input
                    name="image"
                    type="file"
                    onChange={(e) => handleChangeReview(e, ind)}
                  />
                </fieldset>
                <fieldset>
                  <button name="submit" type="submit" disabled={isSubmitting}>
                    Send
                  </button>
                </fieldset>
                {elem.show && (
                  <div className={style.success}>
                    <i className="fa fa-check"></i>
                    Form saved successfully!
                  </div>
                )}
              </form>
            ))}
            <div onClick={addReviewPhoto} className={style.addBtn}>
              ADD
            </div>
          </div>
        </Tab>

        {/* For orders */}
        <Tab eventKey="orders" title="Orders">
          <div className={style.container}>
            <h3>Order Data</h3>
            {orderData.length > 0 ? (
              <table className="table table-bordered table-striped">
                <thead className="table-dark">
                  <tr>
                    <th>#</th>
                    <th>Customer Name</th>
                    <th>Phone Number</th>
                    <th>House Number</th>
                    <th>Area Colony</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Pincode</th>
                    <th>Total Price</th>
                    <th>Payment Method</th>
                    <th>Payment Status</th>
                    <th>Date and time</th>
                    <th>Ordered Items</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData.map((order, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{order.fullname}</td>
                      <td>{order.phonenumber}</td>
                      <td>{order.house_number}</td>
                      <td>{order.area_colony}</td>
                      <td>{order.city}</td>
                      <td>{order.state}</td>
                      <td>{order.pincode}</td>
                      <td>{order.total_price}</td>
                      <td>{order.payment_method}</td>
                      <td>{order.payment_status}</td>
                      <td>{order.created_at}</td>
                      <td>
                        <ul>
                          {(order.cartItems || []).map((item, i) => (
                            <li key={i}>
                              {item.product_name} (Qty: {item.quantity})
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No orders available.</p>
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Dashboard;
