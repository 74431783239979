import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/step1.webp";
import CardImg2 from "../../Assets/step2.webp";
import CardImg3 from "../../Assets/step3.webp";
import CardImg4 from "../../Assets/step4.webp";
import Enquiry from "../../Components/Enquiry";
import TestimonialsSlider from "../../Components/TestimonialsSlider";
import Growbag1 from "../../Assets/Growbag1.webp";
import "../../App.css";
import { Link } from "react-router-dom";

function Grow_bag() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-3">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Grow Bag System
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                Grow Bag System
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="m-3 mb-2">
            <h6 className="text-green m-2">Grow Bag System</h6>
            <h2 className="m-2">Grow Bag System</h2>
            <Row>
              <Col lg={6}>
                <p className="text-muted m-2 text-justify">
                  <h2 className="text-muted m-2">What is a Grow Bag System?</h2>
                  A grow bag system is a simple, flexible, and effective method
                  for growing plants. The grow bag system is gaining popularity
                  among gardeners for both{" "}
                  <span className="fw-bold">indoor</span> and{" "}
                  <span className="fw-bold">outdoor gardening</span>. Grow bags
                  filled with soil or a growing medium allow plants to thrive
                  with optimal root health. The grow bag system is ideal for
                  vegetables, herbs, and small fruits.
                </p>
                <p className="text-muted m-2 text-justify">
                  <ul>
                    <h2 className="text-muted m-2">
                      Key Features of the Grow Bag System
                    </h2>
                    <li>
                      <span className="fw-bold">Growing Medium:</span> Grow bags
                      are filled with high-quality soil or mediums like coco
                      coir, promoting healthy plant growth.
                    </li>
                    <li>
                      <span className="fw-bold">Mobility:</span> Grow bags are
                      portable, making it easy to move plants for optimal
                      sunlight.
                    </li>
                    <li>
                      <span className="fw-bold">Drainage:</span> Grow bags
                      prevent waterlogging with built-in drainage, making them
                      superior to traditional pots.
                    </li>
                    <li>
                      <span className="fw-bold">Flexibility:</span> Grow bags
                      are versatile, allowing users to cultivate a wide variety
                      of plants, from leafy greens to herbs and fruits.
                    </li>
                  </ul>
                </p>
                <p className="text-muted m-2 text-justify">
                  <ul>
                    <h2 className="text-muted m-2">
                      Benefits of Using Grow Bags
                    </h2>
                    <li>
                      <span className="fw-bold">Cost-Effective:</span> The grow
                      bag system offers an affordable way to grow plants, making
                      it accessible to all gardeners.
                    </li>
                    <li>
                      <span className="fw-bold">Ease of Use:</span> Grow bags
                      are easy to use, requiring minimal effort to maintain.
                    </li>
                    <li>
                      <span className="fw-bold">Versatility:</span> Grow bags
                      work in <span className="fw-bold">indoor</span> and{" "}
                      <span className="fw-bold">outdoor</span> environments.
                    </li>
                    <li>
                      <span className="fw-bold">Improved Root Health:</span> The
                      breathable fabric of grow bags encourages root aeration
                      and prevents overwatering.
                    </li>
                  </ul>
                </p>
                <p className="text-muted m-2 text-justify">
                  <h2 className="text-muted m-2">
                    Why Choose the Grow Bag System?
                  </h2>
                  The <span className="fw-bold">grow bag system</span> is a
                  fantastic choice for any gardener. By using grow bags, you can
                  achieve healthier plants, save space, and create a
                  <span className="fw-bold">sustainable garden</span>. Grow bags
                  provide a reliable solution for cultivating{" "}
                  <span className="fw-bold">vegetables, herbs, and fruits</span>
                  in any setting.
                </p>
              </Col>
              <Col className="p-3" lg={6}>
                <img
                  src={Growbag1}
                  alt="grow bag system"
                  className="img-fluid rounded"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <h3 className="p-5 text-center bgyellow">
            <Link
              to="https://wa.me/+919960991166"
              className="text-warning"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us for a detailed quotation.
              <sub className="text-white">Click here</sub>
            </Link>
          </h3>
        </Row>

        <Row>
          <Col lg={12} className=" p-5">
            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-seedling text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow More Food In Less Space
                    </Card.Title>
                    <Card.Text>
                      With protected cultivation and A frame system now we can
                      grow 70 percent more food in the same area.{" "}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-wheat-awn text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Grow anywhere you want
                    </Card.Title>
                    <Card.Text>
                      We can now grow our food anywhere with help of just water
                      and protected cultivation.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-hand-holding-droplet text-green fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">Less water</Card.Title>
                    <Card.Text>
                      Now grow crops on 90% less water compared to field crops.
                      This farming is possible at any location even in water
                      scared zones.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light" className="m-2 shadow">
                  <i class="fa-solid fa-bolt text-green  fs-1 px-3 pt-2 text-center"></i>
                  <Card.Body>
                    <Card.Title className="text-center">
                      High value cash crops
                    </Card.Title>
                    <Card.Text>
                      Not only our daily crops but we can grow super-crops or
                      very high value cash crops with controlled environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <h6 className="text-green m-2 text-center">
              Our Process In Some Steps
            </h6>
            <h4 className="m-2 text-center fw-bold">
              Building Commercially Successful Hydroponic Farm
            </h4>
          </Col>
        </Row>
        <Row className="p-2 m-3">
          {/* second cards */}
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg1} alt="Step One" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Site Feasibility
                </Card.Title>
                <Card.Text className=" text-white p-1">
                  We help you identify a site location to set up our hydroponic
                  project. We consider locality, levelling of land, examine
                  electricity and water also gather basic information of
                  surrounding that can help us test feasibility.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg2} alt="Step Two" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Ideal Farm Construction
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once financial feasibility is analysed we start working on the
                  Construction of the Project. Based on site feasibility and
                  market research we choose between NVPH or Fan-Pad system and
                  also hydroponic system between NFT or Dutch Bucket.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg3} alt="Step Three" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Farm Management{" "}
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once construction is completed, we work on automating the farm
                  using IoT devices as well as integrating with farm management.
                  At this stage, we also start training your field team to make
                  sure we continuously grow high quality produce.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} className="p-3">
            <Card bg="dark" className="shadow">
              <Card.Img variant="top" src={CardImg4} alt="Step Four" />
              <Card.Body>
                <Card.Title className="text-center text-yellow p-2">
                  Contract & Markerting Your Product
                </Card.Title>
                <Card.Text className="text-white p-1">
                  Once our field team is ready and the complete farm is
                  automated, Our team of agronomists, sales and planning starts
                  working on the contract and set a selling mechanism for your
                  farm, with a schedule of crops.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <TestimonialsSlider />
      <Enquiry />
    </>
  );
}
export default Grow_bag;
