import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import hydroponic_farm_img from "../../Assets/hydroponic_farm_img.webp";
import "./blog.css";
import "animate.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

function Hydroponic_Farming() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={hydroponic_farm_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "70%" }}
          alt="Hydroponics farm"
        />
      </div>

      <Row className="m-2">
        <Col
          className="p-4 textJustify"
          sm={12}
          md={9}
          xs={12}
          style={{ margin: "auto", fontSize: "1rem", lineHeight: "1.6" }}
          id="content"
        >
          <div
            id="blog1"
            className="border p-3"
            style={{ borderRadius: "10px" }}
          >
            <h1 className="text-center mb-3">Hydroponic Farming</h1>{" "}
            <p className="pb-3">
              Hydroponic farming is revolutionizing the way we grow food. As
              climate change accelerates, traditional farming practices are
              facing increasing challenges. Hydroponic farming, which involves
              growing plants without soil using nutrient-rich water, provides a
              sustainable solution to these challenges. By using advanced
              technology and controlled environments, hydroponic farming allows
              crops to thrive regardless of weather conditions, offering a
              climate-resilient alternative for food production.
            </p>
            <h4>What is Hydroponic Farming?</h4>
            <p>
              Hydroponic farming is a modern agricultural technique that allows
              plants to grow in a soil-free, nutrient-rich water solution. This
              method provides several benefits over traditional farming, such as
              faster growth rates, higher yields, and reduced water usage. In a
              hydroponic farming system, plants receive all the nutrients they
              need directly from the water, making it a highly efficient way to
              produce food in any climate.
            </p>
            <p className="pb-4">
              The main advantage of hydroponic farming is that it allows crops
              to grow in areas with poor soil quality or where traditional
              farming methods would be difficult. It is an ideal solution for
              urban farming, rooftop gardens, and regions with water scarcity,
              where conventional agriculture may not be feasible.
            </p>
            <h4>Hydroponic Farming and Climate Change:</h4>
            <p>
              Climate change has made traditional farming increasingly
              unreliable. Droughts, floods, and unpredictable weather patterns
              are affecting crop yields worldwide. Hydroponic farming, however,
              is highly adaptable to changing climate conditions. By growing
              plants in a controlled environment, hydroponic systems can provide
              year-round harvests without being affected by external climate
              factors like temperature, rainfall, or droughts.
            </p>
            <p className="pb-4">
              Furthermore, hydroponic farming uses up to 90% less water than
              traditional farming, making it a crucial solution for regions
              experiencing water scarcity due to climate change. The ability to
              grow crops in a controlled, indoor environment means that
              hydroponic farming can contribute to food security even in regions
              where conventional agriculture may no longer be viable.
            </p>
            <h4>Benefits of Hydroponic Farming:</h4>
            <ul>
              <li>Requires up to 90% less water than traditional farming.</li>
              <li>Can be set up in areas with poor soil or limited space.</li>
              <li>
                Offers year-round crop production, independent of weather
                conditions.
              </li>
              <li>
                Produces higher yields with fewer resources, making it more
                sustainable.
              </li>
              <li>
                Reduces the carbon footprint associated with food
                transportation, as crops can be grown locally.
              </li>
            </ul>
            <p className="pb-4">
              In addition to its environmental benefits, hydroponic farming can
              provide fresh, nutritious produce to urban populations. With
              growing concerns about food security and sustainability,
              hydroponic farming is becoming an increasingly important tool in
              the global effort to address the challenges posed by climate
              change.
            </p>
            <h4>Why Hydroponic Farming is the Future of Agriculture:</h4>
            <p>
              As we look to the future, hydroponic farming is poised to play a
              critical role in feeding a growing global population while
              protecting our environment. This high-tech form of farming is
              adaptable, efficient, and sustainable, making it an ideal solution
              to meet the challenges of climate change. As more farmers and
              urban dwellers embrace hydroponic farming, its potential to
              revolutionize the agricultural industry grows exponentially.
            </p>
            <p className="pb-4">
              At Royal Green House (RGCPL), we are committed to helping farmers
              and businesses implement hydroponic farming systems that are
              tailored to their specific needs. We offer comprehensive
              solutions, from system design and installation to ongoing support,
              ensuring that your hydroponic farming venture is a success. With
              our expertise in hydroponics, we help you navigate the transition
              to more sustainable and efficient farming practices.
            </p>
            <a href="http://www.royalgreenhouse.co.in/" target="_blank">
              <h4>Why Choose Hydroponic Farming with RGCPL?</h4>
            </a>
            <p>
              By choosing hydroponic farming with RGCPL, you're not only
              adopting a more sustainable way of growing crops, but you're also
              future-proofing your agricultural operations. Our hydroponic
              systems are designed to deliver the highest yields with minimal
              resource usage, providing you with a competitive edge in an
              ever-changing climate.
            </p>
            <p>
              RGCPL provides the tools, knowledge, and support necessary to
              succeed in hydroponic farming, whether you're growing for local
              markets, urban farms, or large-scale commercial operations. By
              using advanced technology and precision growing methods, we help
              you grow food that is not only healthier and fresher but also
              produced in an environmentally responsible way.
            </p>
            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon icon={faPhone} className="me-2 text-success" />
              <span>
                Phone:{" "}
                <a
                  href="tel:+919960991166"
                  className="text-primary text-decoration-none"
                >
                  +91-9960-991-166
                </a>
              </span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="me-2 text-success"
              />
              <span>
                Email:{" "}
                <a
                  href="mailto:info@royalgreenhouse.co.in"
                  target="_blank"
                  className="text-primary text-decoration-none"
                >
                  info@royalgreenhouse.co.in
                </a>
              </span>
            </div>
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faGlobe} className="me-2 text-success" />
              <span>
                Website:{" "}
                <a
                  href="http://www.royalgreenhouse.co.in/"
                  className="text-primary text-decoration-none"
                >
                  www.royalgreenhouse.co.in
                </a>
              </span>
            </div>
            <p>
              Hydroponic farming is not just a trend—it's the future of
              agriculture. As we face an uncertain climate and an ever-growing
              global population, hydroponic farming provides the solution we
              need to secure our food future.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Hydroponic_Farming;
