import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory4 from "../../Assets/accessory4.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Durling_Clamps = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 50,
      name: "Durling Clamps",
      price: 2,
      image: accessory4,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory4}
            alt="Durling Clamps"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Durling Clamps</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Greenhouse Durling Clamp is a vital accessory used for
                  securing and mounting pipes in polyhouse and greenhouse
                  structures. Its sturdy design ensures that pipes remain firmly
                  in place, providing structural stability and long-lasting
                  support in various weather conditions.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Pieces.</i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> High-Quality
                      Plastic and Galvanized Iron
                    </li>
                    <li>
                      <span className="fw-bold">Usage/Application</span>{" "}
                      Securing and fastening pipes in polyhouse and greenhouse
                      structures
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> White (Plastic),
                      Silver
                    </li>
                    <li>
                      <span className="fw-bold">Dimensions:</span> 4 inches x
                      1.5 inches (approx.)
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      1000 Pieces
                    </li>
                    <li>
                      <span className="fw-bold">Manufactured by:</span> Royal
                      Green House (In-House Production – India)
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    Strong Grip: Firmly secures pipes, ensuring stability in
                    polyhouse structures.
                  </li>
                  <li>
                    Corrosion-Resistant: Galvanized coating prevents rust,
                    making it suitable for long-term outdoor use.
                  </li>
                  <li>
                    Easy Installation: Simple clamp mechanism allows for quick
                    and secure pipe attachment.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Manufactured by:</span> Royal
                      Green House
                    </li>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0001
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days{" "}
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Gujarat, Maharashtra{" "}
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span>{" "}
                      10,000 Pieces Per Day{" "}
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Boxed
                      Packaging to Ensure Safety During Transit{" "}
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment{" "}
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">
          Why Choose Royal Green House for Greenhouse Durling Clamps?
        </h5>
        <p className="mt-1 text-justify">
          Royal Green House provides high-quality Plastic and Metal Greenhouse
          Durling Clamps, designed to offer sturdy and reliable support for
          pipes and structural components in greenhouses, polyhouses, and net
          houses. These clamps are essential for ensuring structural integrity
          by securely fastening pipes to support frames, enabling a stable and
          durable greenhouse setup. With their robust and flexible design,
          Durling Clamps are perfect for long-term agricultural use, providing
          lasting support in various weather conditions.
        </p>
        <h5 className="mt-3">Key Features:</h5>
        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Strong Grip:</span> Firmly secures
              pipes, ensuring stability in polyhouse structures.
            </li>
            <li>
              <span className="fw-bold">Corrosion-Resistant:</span> Galvanized
              coating prevents rust, making it suitable for long-term outdoor
              use.
            </li>
            <li>
              <span className="fw-bold">Easy Installation:</span> Simple clamp
              mechanism allows for quick and secure pipe attachment.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Applications of Greenhouse Durling Clamps:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Used to fasten pipes to the frame or structure of greenhouses.
            </li>
            <li>
              Provides additional support and stability for polyhouse frames in
              areas exposed to high wind or stress.
            </li>
          </ul>
          <br />
          For inquiries and orders, contact Royal Green House today for reliable
          net house repair solutions!
          <h5 className="mt-3">Contact Us:</h5>
          <div className="text-sm-start text-center text-muted">
            <i className="fas fa-phone-alt"></i>
            <a href="tel:+919960991166" className="ms-2">
              +91-9960-991-166
            </a>
          </div>
          <br />
          <div className="text-sm-start text-center text-muted">
            <i className="fas fa-envelope"></i>
            <a
              href="mailto:info@royalgreenhouse.co.in"
              target="_blank"
              className="ms-2"
            >
              info@royalgreenhouse.co.in
            </a>
          </div>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Durling_Clamps;
