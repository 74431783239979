import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import polyhouse_bog_img from "../../Assets/polyhouse_blog_img.webp";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import "./blog.css";

function Fanpad_Polyhouse_Farming() {
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container fluid>
      <div className="d-flex justify-content-center">
        <img
          src={polyhouse_bog_img}
          className={`card-img-top img-fluid p-3 rounded animate__animated animate__fadeInDown ${
            showImage ? "show" : ""
          }`}
          style={{ width: "90%" }}
          alt="polyhouse farming"
        />
      </div>
      <Row className="m-3">
        <Col className="p-4 textJustify" sm={12} md={10} lg={8} id="content">
          <div id="blog1" className="border p-2">
            <h1 className="text-center p-3">Fanpad Polyhouse Farming</h1>
            
            <h2 className="fw-bold" style={{ display: "inline" }}>
              Polyhouse farming
            </h2>
            <span>
              is rapidly transforming modern agriculture by offering a{" "}
              <span className="fw-bold">controlled environment</span> that
              enhances crop productivity, quality, and sustainability. At{" "}
              <span className="fw-bold">
                Royal Green House Construction Pvt. Ltd. (RGCPL)
              </span>
              , we leverage our vast expertise to design and build{" "}
              <span className="fw-bold">customized polyhouse solutions</span>{" "}
              that cater to the diverse needs of farmers. Here's a comprehensive
              guide to the{" "}
              <span className="fw-bold">benefits of polyhouse farming</span> and
              the <span className="fw-bold">setup process</span>, highlighting
              RGCPL’s specialized approach to polyhouse construction.
            </span>
            <h2>Benefits of Polyhouse Farming</h2>
            <ul>
              <li>
                <span className="fw-bold">
                  Controlled Environment for Optimal Growth:
                </span>{" "}
                Polyhouses provide a{" "}
                <span className="fw-bold">micro-climate</span> that shields
                crops from extreme weather conditions, pests, and diseases. This
                controlled environment allows for precise regulation of{" "}
                <span className="fw-bold">temperature, humidity</span>, and{" "}
                <span className="fw-bold">light</span>, resulting in{" "}
                <span className="fw-bold">increased yields</span> and superior
                crop quality. Farmers can grow crops more efficiently while
                minimizing risk from external factors.
              </li>
              <li>
                <span className="fw-bold">Extended Growing Seasons:</span> With
                polyhouses, farmers can maintain{" "}
                <span className="fw-bold">
                  optimal growing conditions year-round
                </span>
                , allowing crops to flourish even outside their natural growing
                seasons. This leads to{" "}
                <span className="fw-bold">multiple harvests</span> per year and
                the opportunity to cultivate{" "}
                <span className="fw-bold">high-value crops</span> that typically
                wouldn’t thrive in the local climate.
              </li>
              <li>
                <span className="fw-bold">Water Efficiency:</span> Polyhouse
                farming incorporates advanced{" "}
                <span className="fw-bold">irrigation systems</span> that
                minimize water waste. At RGCPL, we specialize in integrating{" "}
                <span className="fw-bold">hydroponic systems</span> like{" "}
                <span className="fw-bold">NFT (Nutrient Film Technique)</span>{" "}
                and <span className="fw-bold">DWC (Deep Water Culture)</span>,
                ensuring that water and nutrients are delivered directly to
                plant roots with <span className="fw-bold">minimal runoff</span>
                . This significantly enhances water conservation, especially in
                regions with limited water resources.
              </li>
              <li>
                <span className="fw-bold">
                  Enhanced Pest and Disease Control:
                </span>{" "}
                The enclosed structure of a polyhouse limits exposure to pests
                and diseases, reducing the need for chemical pesticides. This
                results in{" "}
                <span className="fw-bold">cleaner, healthier produce</span>{" "}
                while lowering the costs and environmental impact of chemical
                usage. It's an ideal solution for producing{" "}
                <span className="fw-bold">organic crops</span> in a safer and
                more sustainable manner.
              </li>
              <li>
                <span className="fw-bold">Maximized Resource Efficiency:</span>{" "}
                Polyhouses make the most of available land and resources by{" "}
                <span className="fw-bold">optimizing space</span> and improving{" "}
                <span className="fw-bold">nutrient delivery</span>. With
                efficient use of inputs, polyhouse farming offers{" "}
                <span className="fw-bold">
                  higher productivity per square meter
                </span>{" "}
                compared to traditional farming methods, ensuring farmers get
                the best returns on their investment.
              </li>
            </ul>
            <h2 className="d-inline-block me-3">Polyhouse Setup</h2>
            <h3 className="d-inline-block">: RGCPL’s Expert Approach</h3>
            <p>
              At RGCPL, we adhere to a{" "}
              <span className="fw-bold">structured process</span> to create
              durable, high-performing polyhouses. Here’s an overview of our{" "}
              <span className="fw-bold">polyhouse setup process</span>:
            </p>
            <ol>
              <li>
                <span className="fw-bold">Site Preparation:</span> Proper site
                preparation is key to a successful polyhouse installation. Our
                team conducts a thorough{" "}
                <span className="fw-bold">site assessment</span>, ensuring the
                land is level and suitable for polyhouse construction. We
                evaluate factors such as{" "}
                <span className="fw-bold">soil type</span>, drainage, and
                overall site conditions to guarantee a stable foundation.
              </li>
              <li>
                <span className="fw-bold">Foundation Work:</span> Our polyhouse
                structures are built with{" "}
                <span className="fw-bold">high-strength GI pipes</span> (60 mm
                OD or more), anchored securely with a{" "}
                <span className="fw-bold">cement concrete mixture</span> (1:2:4)
                for maximum durability. The foundation depth is tailored to the
                site’s <span className="fw-bold">soil conditions</span> and{" "}
                <span className="fw-bold">wind speeds</span>, ensuring the
                structure can withstand harsh environmental factors.
              </li>
              <li>
                <span className="fw-bold">Polyhouse Frame Construction:</span>{" "}
                RGCPL uses <span className="fw-bold">premium materials</span> to
                construct polyhouse frames capable of withstanding wind speeds
                up to 120 km/hr. Our standard bay size is 8m x 4m, with a ridge
                height of 6-7 meters and a gutter height of 4-5 meters, ensuring
                optimal{" "}
                <span className="fw-bold">airflow, sunlight penetration</span>,
                and <span className="fw-bold">humidity</span> control for crops.
              </li>
              <li>
                <span className="fw-bold">
                  Installation of Cladding and Accessories:
                </span>{" "}
                We use <span className="fw-bold">Polyfim cladding</span>, which
                offers multiple benefits, including{" "}
                <span className="fw-bold">
                  thermal insulation, anti-mist, anti-dust
                </span>
                , and <span className="fw-bold">light diffusion</span>{" "}
                properties. This specialized cladding material ensures the
                internal environment is ideal for plant growth. We also install{" "}
                <span className="fw-bold">foggers, irrigation systems</span>,
                and other accessories to maintain{" "}
                <span className="fw-bold">optimal growing conditions</span>.
              </li>
              <li>
                <span className="fw-bold">Hydroponic System Integration:</span>{" "}
                RGCPL integrates cutting-edge hydroponic systems like{" "}
                <Link to="/NftAframe" style={{ textDecoration: "none" }}>
                  NFT (Nutrient Film Technique)
                </Link>
                ,{" "}
                <Link to="/DWC" style={{ textDecoration: "none" }}>
                  DWC (Deep Water Culture)
                </Link>
                , and <span className="fw-bold">vertical farming systems</span>{" "}
                to enhance crop yields and make farming more efficient. These
                systems are optimized to work within the{" "}
                <span className="fw-bold">polyhouse environment</span> for
                maximum productivity.
              </li>
            </ol>
            <h2>Why Choose RGCPL for Polyhouse Farming Setup?</h2>
            <ul>
              <li>
                <span className="fw-bold">Expert Design and Consultation</span>:
                Our team works closely with farmers to design tailored solutions
                for polyhouse farming, ensuring the system meets all the
                specific needs of the farm.
              </li>
              <li>
                <span className="fw-bold">Complete Turnkey Solutions:</span> We
                handle every aspect of polyhouse farming setup, from{" "}
                <span className="fw-bold">site selection</span> to
                post-installation support, ensuring a hassle-free experience for
                our clients.
              </li>
              <li>
                <span className="fw-bold">
                  Comprehensive After-Sales Service:
                </span>{" "}
                Our commitment to customer satisfaction extends beyond
                installation, with ongoing support and maintenance services to
                ensure optimal performance.
              </li>
            </ul>
            <h2 className="text-center">
              Start Your Polyhouse Farming Journey with RGCPL Today!
            </h2>
            <div className="d-flex justify-content-center mt-4">
              <Link to="/RequestAQuote">
                <Button variant="success" size="lg">
                  Get a Free Quote Now!
                </Button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Fanpad_Polyhouse_Farming;
