import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory14 from "../../Assets/accessory14.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Twisted_Rope_Tensioning_Rope = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 60,
      name: "Twisted Rope (Tensioning Rope)",
      price: 250,
      image: accessory14,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory14}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Twisted Rope (Tensioning Rope)</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Twisted Rope is designed for tensioning and securing
                  greenhouse coverings. Made from durable synthetic fibers, this
                  rope offers flexibility and strength, making it ideal for
                  various greenhouse applications.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> High-strength
                      synthetic fibers
                    </li>
                    <li>
                      <span className="fw-bold">Diameter:</span> 10 mm (or as
                      required)
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> Green (to blend
                      with greenhouse environment)
                    </li>
                    <li>
                      <span className="fw-bold">Length:</span> Available in
                      custom lengths
                    </li>
                    <li>
                      <span className="fw-bold">Usage/Application:</span> Secure
                      poly sheets and netting in greenhouse structures
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 2-3 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>Eco-friendly: Made from recyclable materials.</li>
                  <li>
                    High Strength: Withstands heavy loads and tension without
                    breaking.
                  </li>
                  <li>
                    Durable Material: Resistant to UV rays and environmental
                    wear.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">Importance of Twisted Rope (Tensioning Rope):</h5>
        <p className="text-justify">
          A high-quality tensioning rope is essential for maintaining the
          stability of greenhouse coverings, ensuring plants remain protected
          from environmental conditions.
        </p>
        <h5 className="mt-3">
          Key Benefits of Twisted Rope (Tensioning Rope):
        </h5>

        <p className="text-justify">
          <ul>
            <li>Suitable for securing netting and covers in greenhouses.</li>
            <li>Prevents sagging or movement caused by wind or heavy rain.</li>
            <li>Easy to cut and adjust to various lengths as needed.</li>
          </ul>
        </p>
        <h5 className="mt-3">
          Applications of Twisted Rope (Tensioning Rope):
        </h5>
        <p className="text-justify">
          <ul>
            <li>
              Used to secure poly sheets and netting in greenhouse structures.
            </li>
            <li>Ideal for creating tensioning systems in low tunnels.</li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Twisted_Rope_Tensioning_Rope;
