import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";

import Card from "react-bootstrap/Card";
import CardImg1 from "../../Assets/gallery/product1.webp";
import CardImg2 from "../../Assets/gallery/product2.webp";
import CardImg3 from "../../Assets/gallery/product4.webp";
import CardImg4 from "../../Assets/gallery/product3.webp";
import CardImg5 from "../../Assets/gallery/product5.webp";
import RedCherryTomatoes from "../../Assets/cherrytomato.png";
import YellowCherryTomatoes from "../../Assets/yellowcherry.webp";
import BeefSteakTomatoes from "../../Assets/beeftomato.webp";
import EuropeanCucumbers from "../../Assets/eurocucumbers.webp";
import BellPeppers from "../../Assets/bellpeppers.webp";
import SnackPeppers from "../../Assets/snackpeppers.webp";
import Zucchini from "../../Assets/zucchini.webp";
import Broccoli from "../../Assets/broccoli.webp";
function VineCrops() {
  return (
    <Container fluid>
      <Row className="headingimg">
        <Col className="m-5">
          <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
            {" "}
            Products
          </h1>
          <div className="text-white m-4 fw-bold text-center">
            <a href="#" className=" text-white m-3 text-decoration-none">
              Home
            </a>
            <i className="fa-solid fa-circle-arrow-right fa-beat"></i>
            <a className="text-white text-decoration-none m-3">Products</a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h2 className="text-green m-3 text-center fw-bold">
            Our Fresh Produce
          </h2>
          <h6 className="text-center m-4 fw-bold">
            All our produce is fresh, nutritious and hand-picked. <br /> We are
            dedicated to deliver to you the top-shelf produce.
          </h6>
        </Col>
      </Row>
      <Row className="p-2">
        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg1} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Vine Crops
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg2} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Lettuces
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg3} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Leafy Greens
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

       
      </Row>

      <Row className="row justify-content-center">
      <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg4} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Fresh Herbs
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="p-2 ">
          <Card bg="dark" className="shadow">
            <Card.Img variant="top" src={CardImg5} />
            <Card.Body>
              <Card.Title className="text-center text-yellow p-2">
                Floriculture
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="mt-4 pt-4 ">
          <h1 className="text-green m-3 text-center fw-bold">Vine Crops</h1>
          
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Red Cherry Tomatoes
              </h2>
              <p className="text-center ">
                Bite sized, juicy tomatoes.
                <br />
                Cherry tomatoes are perfect for salads, pizza toppings or
                pastas.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Rich in Vitamin A, C, E</li>
                    <li>Good source of Iron, Potassium and Calcium</li>
                    <li>
                      Contains micronutrients like lycopene, beta carotene and
                      lutein
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Aids in improvement of heart health</li>
                    <li>Supports bone health</li>
                    <li>Good for the skin</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img
                src={RedCherryTomatoes}
                alt="Vine crops"
                className="img-fluid p-3"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img
                src={YellowCherryTomatoes}
                alt="vine crops farming"
                className="img-fluid p-3"
              />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Yellow Cherry Tomatoes
              </h2>
              <p className="text-center ">
                Bite sized, juicy tomatoes.
                <br />
                Cherry tomatoes are perfect for salads, pizza toppings or
                pastas.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Rich in Vitamin A, C, E</li>
                    <li>Good source of Iron, Potassium and Calcium</li>
                    <li>
                      Contains micronutrients like lycopene, beta carotene and
                      lutein
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Aids in improvement of heart health</li>
                    <li>Supports bone health</li>
                    <li>Good for the skin</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Beef Steak Tomatoes
              </h2>
              <p className="text-center ">
                Despite botanically being a fruit, it’s generally eaten and
                prepared like a vegetable
                <br />
                Fresh tomatoes are low in carbs.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Rich in vitamin C, potassium, folate, and vitamin K</li>
                    <li>Good source of Iron, Potassium and Calcium</li>
                    <li>
                      Contains micronutrients like lycopene, beta carotene and
                      lutein
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Aids in improvement of heart health</li>
                    <li>Supports bone health</li>
                    <li>Good for the skin</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img
                src={BeefSteakTomatoes}
                alt="Tomatoes hydroponic farm"
                className="img-fluid p-3"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img src={BellPeppers} alt="Bell pepper farms" className="img-fluid p-3" />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Bell Peppers
              </h2>
              <p className="text-center ">Low-calorie crunch with every bite</p>

              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>High in Vitamin B6, C, E</li>
                    <li>Contains Beta-carotene</li>
                    <li>Concentrated with Iron</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Reduces the risk of anaemia</li>
                    <li>Protects against heart diseases</li>
                    <li>Delays age-related memory loss</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Snack Peppers
              </h2>
              <p className="text-center ">
                Pepper with a fruity flavour
                <br />
                These are deliciously sweet, snack-sized peppers that are great
                in salads and stir fries.
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Excellent source of Vitamins A, B6, K and C</li>
                    <li>
                      Rich source of antioxidants like carotenes, lutein and
                      cryptoxanthin
                    </li>
                    <li>
                      Minerals like Iron, Copper, Zinc, Potassium and Manganes
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Improves dietary iron absorption</li>
                    <li>Facilitates cellular metabolism</li>
                    <li>Helps in treatment of anaemia</li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={SnackPeppers} alt="snack pepper farm" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img
                src={EuropeanCucumbers}
                alt="Cucumber farms"
                className="img-fluid p-3"
              />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                European Cucumbers
              </h2>
              <p className="text-center ">
                Cucumbers are crisp and refreshing due to their high water
                content
                <br />
                Cucumber is a low-calorie food that is primarily water
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Rich in vitamin K, C, and potassium</li>
                    <li>Contains several phytochemicals</li>
                    <li>
                      Cucumber is a low-calorie food that is primarily water
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Improves Blood Sugar Control</li>
                    <li>
                      Fiber improves satiety and helps keep cholesterol levels
                      down
                    </li>
                    <li>Promotes Hydration</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Zucchini
              </h2>
              <p className="text-center ">
                Zucchini, also called courgette in some parts of the world
                <br />A favorite among low-carb dieters and anyone who wants to
                lose weight fast
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Particularly high vitamin A content</li>
                    <li>Contains both soluble and insoluble fiber</li>
                    <li>
                      Rich in carotenoids such as lutein, zeaxanthin, and
                      beta-carotene
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>
                      Reduces the risk of constipation and symptoms of various
                      gut disorders
                    </li>
                    <li>
                      May lower blood pressure, cholesterol, and other risk
                      factors for heart disease
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <img src={Zucchini} alt="Zucchini hydroponic farm" className="img-fluid p-3" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-3">
        <Col
          className="bg-light rounded-pill p-3 d-flex justify-content-center align-items-center "
          sm={12}
        >
          <Row className="d-flex justify-content-center align-items-center ">
            <Col lg={6}>
              <img src={Broccoli} alt="Brocoli hydroponic farm" className="img-fluid p-3" />
            </Col>
            <Col lg={6}>
              <h2 className="d-flex justify-content-center align-items-center fw-bold fs-1">
                Broccoli
              </h2>
              <p className="text-center ">
                Broccoli can be enjoyed both raw and cooked
                <br />
                Raw broccoli contains almost 90% water, 7% carbs, 3% protein,
                and almost no fat
              </p>
              <Row>
                <Col>
                  <ul>
                    <span className="fw-bold">Nutrient Overview:</span>
                    <li>Higher in protein than most vegetables</li>
                    <li>
                      Rich in folate, potassium, manganese, iron, and vitamins C
                      and K1
                    </li>
                    <li>
                      Low in digestible carbs but provides a decent amount of
                      fiber
                    </li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <span className="fw-bold"> Health Benefits:</span>
                    <li>Helps lower cholesterol and boosts eye health</li>
                    <li>
                      Promotes gut health and may reduce your risk of various
                      diseases
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default VineCrops;
