import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import WorkImg from "../../Assets/workimg.webp";
import Card from "react-bootstrap/Card";

function WorkWithus() {
  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              Join us as Business Associate
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i class="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">
                Work With Us
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="m-4">
            <h1 className="text-center p-2">WE WANT YOU TO JOIN US!</h1>
            <h4 className="text-center text-muted p-2">
              We are looking for an energetic business development associate to
              help drive our sales and grow the business. You will attract new
              clients and build solid relationships with them while maintaining
              existing relationships. Other duties will include market research,
              developing business strategies and pitches, managing a variety of
              projects, and identifying new business opportunities. You will
              work with various departments and report to the Head of Business
              Development.
            </h4>
          </Col>
        </Row>
        <Row className="bglightGreen">
          <Col lg={6}>
            <img
              src={WorkImg}
              alt="hydroponics farm near me"
              className="img-fluid"
            />
          </Col>
          <Col lg={6} className="p-3">
            <h3 className="text-white text-center p-3">
              Join the team of winners.
            </h3>
            <Row>
              <Col lg={6}>
                <Card border="light">
                  <i class="fa-solid fa-sack-dollar text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>Earn Exponentially</Card.Title>
                    <Card.Text>
                      With the right people on your side and technology backing
                      you, grow rapidly with us and make high profits on every
                      business opportunity.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light">
                  <i class="fa-solid fa-wand-magic-sparkles text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>Dominate your space</Card.Title>
                    <Card.Text>
                      Don't just wait for business to happen. Get access to our
                      thousands of laser focus leads and start converting. We
                      want you to dominate your space.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="m-3 p-3">
                <h4 className="text-white p-2">Why join us?</h4>
                <ul className="text-white p-4">
                  <li>High Profit</li>
                  <li>No Risk</li>
                  <li>Reputed Brand</li>
                  <li>End to End Support</li>
                  <li>No Experience Required</li>
                  <li>Complete Training</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="m-3 p-3">
          <Col lg={6} className="mb-2">
            <h6 className="text-green m-2">Perks of working with us</h6>
            <h2 className="m-2 py-2">
              Team up with a growing company in the field of modern agriculture.
            </h2>
            <p className="text-muted m-2 py-2">
              We are always looking for an energetic business development
              associate to help drive our sales and grow the business. By
              providing a thriving environment for you, complete backend support
              and marketing help, build your career in business association with
              us.
            </p>
            <h6 className="text-muted m-2">Hyper-Local Support</h6>
            <p className="text-muted m-2 py-2">
              We provide hyper-local support for your success. From marketing
              support to sales conversion help, We got you completely covered.
            </p>
            <h6 className="text-muted m-2"> Shared Responsibilities</h6>

            <p className="text-muted m-2 py-2">
              From developing and implementing sales strategies, client service
              and retention plans, and analyzing sales data to inform or update
              marketing strategies. Assisting with sales pitches, presentations,
              reference material, and we both will work together.
            </p>
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={6}>
                <Card border="light">
                  <i className="fa-solid fa-hands-holding-circle text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>Resourceful</Card.Title>
                    <Card.Text>
                      You will always get what you need for any conversion. Be
                      it visiting cards or custom-designed business plans.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light">
                  <i className="fa-solid fa-headset text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>Direct line connection</Card.Title>
                    <Card.Text>
                      Get a direct line connection from us. Now your leads can
                      directly talk to you from our official numbers.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Card border="light">
                  <i className="fa-solid fa-store text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>Marketing Support</Card.Title>
                    <Card.Text>
                      Get complete marketing support from us for social media,
                      web-search and email / SMS campaigns.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light">
                  <i className="fa-solid fa-chalkboard-user text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>Transparent Process</Card.Title>
                    <Card.Text>
                      Get complete transparent access to sales data and process
                      stages along with billings / Invoices.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Card border="light">
                  <i className="fa-solid fa-graduation-cap text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>Complete Training</Card.Title>
                    <Card.Text>
                      Our sales have years of experience and help you in
                      onboarding in less than 3 days with us. With regular calls
                      and community support always be updated.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card border="light">
                  <i className="fa-solid fa-book text-green fs-1 px-3 pt-2"></i>
                  <Card.Body>
                    <Card.Title>Document Proof</Card.Title>
                    <Card.Text>
                      With agreement and contract of onboarding, We follow every
                      legal process. From TDS to tax filing, we help you manage
                      everything.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="p-3 fw-bold">Responsibilities</h2>
            <ul>
              <li className="text-muted p-1">
                You must be willing to familiarize yourself with the company’s
                vision and mission.
              </li>
              <li className="text-muted p-1">
                You should be able to Conduct market research and identify
                potential clients.
              </li>
              <li className="text-muted p-1">
                You should be able to cultivate strong relationships with
                clients.
              </li>
              <li className="text-muted p-1">
                You need to work closely with staff across departments to
                implement growth strategies.
              </li>
              <li className="text-muted p-1">
                Assist with drafting sales pitches, presentations, reference
                material, and other documents as required.
              </li>
              <li className="text-muted p-1">
                Identify new business opportunities and partners.
              </li>
              <li className="text-muted p-1">
                Strong interpersonal skills with the ability to engage with
                various levels of management, staff, and clients.
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <h5 className="text-muted p-1 text-center my-4">
          Get in Touch with us today at contact@royalgreenhouse.com or give us a
          call at +91-0000000000
        </h5>
      </Container>
    </>
  );
}

export default WorkWithus;
