import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabTruncheonNutrientMeter from "../../Assets/Bluelab Truncheon Nutrient Meter.webp";
import "bootstrap/dist/css/bootstrap.min.css"; 

const Bluelab_Truncheon_Nutrient_Meter = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 39,
      name: "Bluelab Truncheon Nutrient Meter",
      price: 11340,
      image: BluelabTruncheonNutrientMeter,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabTruncheonNutrientMeter}
                  alt="bluelab truncheon nutrient meter"
                  className="d-block w-100"
                />
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab Truncheon Nutrient Meter</h2>
            <p className="product-price">₹{11340}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>Unmatched Durability – An EC Meter Built to Last</h5>
              Engineered for resilience, this impossibly tough EC meter is
              designed to deliver reliable performance over a lifetime. Whether
              submerged, splashed, or exposed to liquids, this fully waterproof
              meter ensures accurate measurements with minimal hassle.
              <h5 className="mt-2">Key Features:</h5>
              <ul>
                <li>
                  Measures up to 3.6 EC, 36 CF, 1800 ppm (PPM 500) or 2520 ppm
                  (PPM 700) for comprehensive monitoring.
                </li>
                <li>
                  Robust and long-lasting construction provides exceptional
                  value and dependability.
                </li>
                <li>
                  The long-format design makes it ideal for mixing and measuring
                  large nutrient reservoirs at the same time.
                </li>
              </ul>
              Additionally, this EC meter requires{" "}
              <span className="fw-bold">no calibration</span> and is easy to
              clean and disinfect. With{" "}
              <span className="fw-bold">all conductivity scales included</span>
              —EC, CF, PPM 500 (TDS), PPM 700—you’re equipped for any nutrient
              measurement needs. Plus, it’s backed by an{" "}
              <span className="fw-bold">
                industry-leading five-year warranty
              </span>{" "}
              for peace of mind.
              <i>
                Note: Packaging may vary depending on the version you receive.
              </i>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_Truncheon_Nutrient_Meter;
