import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory34 from "../../Assets/accessory34.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Greenhouse_Pipe_Support_Clamp = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 80,
      name: "Greenhouse Pipe Support Clamp (Bracket Type)",
      price: 250,
      image: accessory34,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory34}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Greenhouse Pipe Support Clamp (Bracket Type)
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  This Greenhouse Pipe Support Clamp is designed to securely
                  hold pipes in place for structural support in greenhouse
                  frameworks. The clamp offers a snug fit and reinforces areas
                  where pipes meet or turn, ensuring stability and durability.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span>Galvanized Iron
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> Black
                    </li>

                    <li>
                      <span className="fw-bold">Dimensions:</span> 2 inches x 1
                      inch (approx.)
                    </li>
                    <li>
                      <span className="fw-bold">Usage/Application:</span> Used
                      for securing pipes in greenhouses, polyhouses, or other
                      structural assemblies
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 5-7 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    <span className="fw-bold">Sturdy Construction:</span>{" "}
                    Designed to hold pipes securely, even under pressure.
                  </li>
                  <li>
                    <span className="fw-bold">Corrossion Resistant:</span>
                    The galvanized steel provides protection against rust and
                    weathering.
                  </li>
                  <li>
                    <span className="fw-bold">Easy Installation:</span>
                    Comes with pre-drilled holes for quick and seamless
                    attachment.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">Key Benefits of Greenhouse Pipe Support Clamp:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Durable Support: Provides firm grip on pipes, ensuring structural
              integrity.
            </li>
            <li>
              Weather-Resistant: Suitable for outdoor use due to its anti-rust
              coating.
            </li>
          </ul>
        </p>

        <h5 className="mt-3">Applications of Greenhouse Pipe Support Clamp:</h5>
        <p className="text-justify">
          <ul>
            <li>Securing and stabilizing pipes in greenhouse frameworks.</li>
            <li>Ideal for small-to-medium-scale greenhouse installations.</li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Greenhouse_Pipe_Support_Clamp;
