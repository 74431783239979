import React, { useEffect } from "react";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import CardImg1 from "../../Assets/gallery/old/29.webp";
import CardImg2 from "../../Assets/gallery/nflatbed.webp";
import CardImg3 from "../../Assets/gallery/1.webp";
import CardImg4 from "../../Assets/gallery/old/19.webp";
import CardImg5 from "../../Assets/gallery/lightbag.webp";
import CardImg6 from "../../Assets/gallery/multispan.webp";
import CardImg7 from "../../Assets/gallery/vine.webp";
import CardImg8 from "../../Assets/gallery/old/74.webp";
import "../../App.css";
import AOS from "aos";
import "aos/dist/aos.css";

function QuoteCards() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      mirror: true,
    });
    const handleResize = () => {
      AOS.refresh();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="d-flex justify-content-between justify-content-sm-center my-4">
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <h2 className="text-green m-4">Hydroponic Farming in India</h2>
          <h3 className="text-dark m-4">
            Great opportunities for Hydroponic Farming with Royal Green House
          </h3>
        </Col>
        <Col lg={6}>
          <p className="text-dark mt-5 pt-4 px-2">
            Royal Green House Construction India Private Limited is a trusted
            name engaged in providing Construction Services for Commercial Farm
            Setup like Natural Ventilated Poly Houses, Fan & Pad Poly Houses and
            Shade Houses.
          </p>
          <Link to="/RequestAQuote" className="btn btn-success m-3">
            <i className="fa-solid fa-circle-arrow-right fa-beat"></i> Request A
            Quote
          </Link>
          <Link to="/OurProjects" className="btn btn-outline-success m-3">
            <i className="fa-solid fa-circle-arrow-right fa-beat"></i> Our
            Hydroponic Projects
          </Link>
        </Col>
      </Row>
      <Carousel className="p-2" interval={2500000} pause={false}>
        <Carousel.Item>
          <Row className="p-2" xs={12}>
            <Col
              lg={3}
              xs={12}
              className="p-3"
              data-aos="flip-right"
              data-aos-delay="150"
            >
              <Card className="square boxShadow" id="quote1">
                <Card.Img
                  variant="top"
                  src={CardImg8}
                  className="curveImg"
                  alt="commercial hydroponic farming"
                />
                <Card.Body className="font-weight-bolder text-dark">
                  <Card.Title className="text-center fa-beat">
                    Naturally Ventilated Polyhouse (NVPH)
                  </Card.Title>
                  <Card.Text>
                    An NVPH (Naturally Ventilated Polyhouse) uses Natural
                    ventilation for climate control. Made of galvanized
                    iron/steel with UV-stabilized polyethylene film, it features
                    roof and side vents for airflow. Cost-effective and
                    energy-efficient, it's ideal for moderate climates,
                    enhancing crop yield while promoting sustainable farming.
                  </Card.Text>
                  <div className="text-center">
                    <Link to="/Nvph" className="px-4 btn btn-dark">
                      Learn NVPH
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col
              lg={3}
              xs={12}
              className="p-2"
              data-aos="flip-right"
              data-aos-delay="350"
            >
              <Card className="boxShadow square" id="quote2">
                <Card.Img
                  variant="top"
                  src={CardImg5}
                  className="curveImg"
                  alt="commercial hydroponic farming systems"
                />
                <Card.Body className="font-weight-bolder text-dark">
                  <Card.Title className="text-center fa-beat">
                    Fan & Pad Polyhouse (Climate Control)
                  </Card.Title>
                  <Card.Text>
                    A Fan Pad Polyhouse uses fans and Cooling pads for climate
                    control. Fans expel hot air, while water-soaked pads cool
                    incoming air. Made from galvanized iron/steel with
                    UV-stabilized polyethylene film, it ensures precise
                    temperature and humidity management, enhancing crop yields
                    and quality in various climates.
                  </Card.Text>
                  <div className="text-center">
                    <Link to="/ClimateControlled" className="px-4 btn btn-dark">
                      Learn Fan & Pad Polyhouse
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col
              lg={3}
              xs={12}
              className="p-2"
              data-aos="flip-right"
              data-aos-delay="550"
            >
              <Card className="square boxShadow" id="quote3">
                <Card.Img
                  variant="top"
                  src={CardImg6}
                  className="curveImg"
                  alt="hydroponics farming"
                />
                <Card.Body className="font-weight-bolder text-dark">
                  <Card.Title className="text-center fa-beat">
                    Shade Net House (Tunnel)
                  </Card.Title>
                  <Card.Text>
                    A Tunnel Net House is a protective structure with a
                    semi-circular frame covered by netting. It shields crops
                    from pests, harsh weather, and excessive sunlight. Made of
                    galvanized iron or steel, it's cost-effective, easy to
                    install, and promotes healthy plant growth in diverse
                    climates. Ideal for vegetables, flowers, and delicate
                    plants.
                  </Card.Text>
                  <div className="text-center">
                    <Link to="/TunnelShadeHouse" className="px-4 btn btn-dark">
                      Learn Shade Net House
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col
              lg={3}
              xs={12}
              className="p-2"
              data-aos="flip-right"
              data-aos-delay="750"
            >
              <Card className="square boxShadow" id="quote4">
                <Card.Img
                  variant="top"
                  src={CardImg7}
                  className="curveImg"
                  alt="hydroponics farms near me"
                />
                <Card.Body className="font-weight-bolder text-dark">
                  <Card.Title className="text-center fa-beat">
                    Wire Rope Net House (Rain Protected)
                  </Card.Title>
                  <Card.Text>
                    A Rain Protected Wire Rope Net House with a solarig sheet on
                    top offers enhanced protection. The solarig sheet shields
                    crops from rain and UV rays, while the wire rope and net
                    structure provide ventilation and support. This setup
                    ensures year-round crop protection, maintaining healthy
                    growth even during the rainy season.
                  </Card.Text>
                  <div className="text-center">
                    <Link
                      to="/RainProtectedWireRopeNetHouse"
                      className="px-4 btn btn-dark"
                    >
                      Learn Wire Rope Net House
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row className="p-3">
            <Col
              lg={3}
              xs={12}
              className="p-2"
              data-aos="flip-right"
              data-aos-delay="150"
            >
              <Card className="square boxShadow" id="quote5">
                <Card.Img
                  variant="top"
                  src={CardImg1}
                  className="curveImg"
                  alt="hydroponic lettuce farm"
                />
                <Card.Body className="font-weight-bolder text-dark">
                  <Card.Title className="text-center fa-beat">
                    Flat Shade Net House
                  </Card.Title>
                  <Card.Text>
                    A Flat Shade Net House uses a flat roof design with UV-
                    stabilized netting to protect crops from excessive sunlight
                    and pests. Made of galvanized iron/steel frames, it's
                    cost-effective, easy to construct, and suitable for a
                    variety of crops. It ensures optimal light diffusion,
                    promoting healthy plant growth in diverse climates.
                  </Card.Text>
                  <div className="text-center">
                    <Link to="/MultispanShadenet" className="px-4 btn btn-dark">
                      Learn Flat Shade Net House
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col
              lg={3}
              xs={12}
              className="p-2"
              data-aos="flip-right"
              data-aos-delay="350"
            >
              <Card className="square boxShadow" id="quote6">
                <Card.Img
                  variant="top"
                  src={CardImg2}
                  className="curveImg"
                  alt="hydroponics farm"
                />
                <Card.Body className="font-weight-bolder text-dark">
                  <Card.Title className="text-center fa-beat">
                    NFT Flatbed
                  </Card.Title>
                  <Card.Text>
                    To setup an NFT Flatbed system with a maximum length of 3.5
                    meters and 9 NFT channels per bed, arrange the channels in
                    parallel on the bed, ensuring each has slight slope (1-3%)
                    for nutrient flow. Position the inlet and outlet for
                    nutrient Solution at opposite ends, and ensure adequate
                    spacing for plant growth and maintenance.
                  </Card.Text>
                  <div className="text-center">
                    <Link to="/NftFlatbed" className="px-4 btn btn-dark">
                      Learn NFT Flatbed
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col
              lg={3}
              xs={12}
              className="p-2"
              data-aos="flip-right"
              data-aos-delay="550"
            >
              <Card className="square boxShadow" id="quote7">
                <Card.Img
                  variant="top"
                  src={CardImg3}
                  className="curveImg"
                  alt="hydroponic farms"
                />
                <Card.Body className="font-weight-bolder text-dark">
                  <Card.Title className="text-center fa-beat">
                    NFT A-Frame
                  </Card.Title>
                  <Card.Text>
                    For an NFT A-Frame setup within a 3.5-meter length,
                    construct a 5-layer system on a stand. Each layer should be
                    spaced evenly to allow for efficient nutrient flow and plant
                    growth. Ensure proper support and alignment to maintain
                    stability and optimize the use of vertical space. Enhances
                    growth, space efficiency, and overall yield.
                  </Card.Text>
                  <div className="text-center">
                    <Link to="/NftAframe" className="px-4 btn btn-dark">
                      Learn NFT A-Frame
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col
              lg={3}
              xs={12}
              className="p-2"
              data-aos="flip-right"
              data-aos-delay="750"
            >
              <Card className="square boxShadow" id="quote8">
                <Card.Img
                  variant="top"
                  src={CardImg4}
                  className="curveImg"
                  alt="cost to operated hydroponic farm"
                />
                <Card.Body className="font-weight-bolder text-dark">
                  <Card.Title className="text-center fa-beat">
                    Vine Crops
                  </Card.Title>
                  <Card.Text>
                    For vine crops, set up double lines of grow slabs with
                    four-way aero drippers. Space the slabs appropriately to
                    ensure optimal root growth and nutrient absorption. Position
                    the aero drippers to deliver precise water and nutrient
                    distribution, maximizing plant health and yield. Ensures
                    even growth, high productivity, and efficient resource use.
                  </Card.Text>
                  <div className="text-center">
                    <Link to="/VineCrops" className="px-4 btn btn-dark">
                      Learn Rain Vine Crops
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>
    </Container>
  );


  
}
export default QuoteCards;
