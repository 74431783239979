import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import reviewImg1 from "../../Assets/map/map11.webp";
import reviewImg2 from "../../Assets/map/map12.webp";
import reviewImg3 from "../../Assets/map/map13.webp";
import reviewImg4 from "../../Assets/map/map14.webp";

function TestimonialsSlider() {
  return (
    <>
      <Container fluid className="sliderbg">
        <Carousel className="p-4 m-1 ">
          <Carousel.Item className="p-3">
            <Row>
              <Col className="p-3 m-2 ">
                <Row className="p-3 ">
                  <Col className="text-end">
                    <img
                      src={reviewImg1}
                      alt="reviewImg1"
                      className="rounded-circle"
                    />
                  </Col>
                  <Col className="p-3 text-white">
                    Royal Green House is the best solution for hydroponic farm
                    <br /> Pink Pearl Jaipur, Rajsthan
                    <div>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star-half-stroke text-warning"></i>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="p-3 m-2 ">
                <Row className="p-3 ">
                  <Col className="text-end">
                    <img
                      src={reviewImg2}
                      alt="reviewImg1"
                      className="rounded-circle"
                    />
                  </Col>
                  <Col className="p-3 text-white">
                    Royal Green House give me very good knowldge of hydroponic
                    farm.
                    <br /> Amodhini - Hosur, Tamil Nadu
                    <div>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star-half-stroke text-warning"></i>
                      <i class="fa-regular fa-star text-warning"></i>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item className="p-3">
            <Row>
              <Col className="p-3 m-2">
                <Row className="p-3 ">
                  <Col className="text-end">
                    <img
                      src={reviewImg3}
                      alt="reviewImg1"
                      className="rounded-circle"
                    />
                  </Col>
                  <Col className="p-3 text-white">
                    The entire team of Royal Green House provided good support.
                    <br /> City Greens, Bangalore, Karnataka
                    <div>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star-half-stroke text-warning"></i>
                      <i class="fa-regular fa-star text-warning"></i>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="p-3 m-2">
                <Row className="p-3 ">
                  <Col className="text-end">
                    <img
                      src={reviewImg4}
                      alt="reviewImg1"
                      className="rounded-circle"
                    />
                  </Col>
                  <Col className="p-3 text-white">
                    Royal Green House is the best solution for hydroponic farm
                    <br /> Farmwala Pune, Maharashtra
                    <div>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star text-warning"></i>
                      <i class="fa-solid fa-star-half-stroke text-warning"></i>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Container>
    </>
  );
}

export default TestimonialsSlider;
