import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory25 from "../../Assets/accessory25.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Round_Pipe_Clamp_Gable_Shaped_Polyhouse_Clamp = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 71,
      name: "Round Pipe Clamp (Gable Shaped Polyhouse Clamp)",
      price: 250,
      image: accessory25,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory25}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Round Pipe Clamp (Gable Shaped Polyhouse Clamp)
            </h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Round Pipe Clamp from RGCPL is designed to offer secure
                  and long-lasting fastening solutions for polyhouses and
                  greenhouses. Made from high-quality galvanized iron, this
                  clamp fits standard GI pipes (2-2.5 inches in diameter)
                  commonly used in greenhouse structures. Its weather-resistant
                  design, combined with eco-friendly recyclable materials,
                  ensures reliable performance for up to 5 years. Whether you're
                  securing plastic films or shade nets, this clamp ensures
                  stability during extreme weather conditions like high winds
                  and heavy rain, making it a must-have for both commercial and
                  small-scale farming operations.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold"> Material:</span>Galvanized Iron
                      (GI)
                    </li>
                    <li>
                      <span className="fw-bold"> Color</span>Silver
                    </li>
                    <li>
                      <span className="fw-bold">Diameter:</span> Fits 2-2.5
                      inches pipes (standard GI pipes used in polyhouses)
                    </li>
                    <li>
                      <span className="fw-bold">Thickness:</span> 2 mm
                    </li>
                    <li>
                      <span className="fw-bold">Shape:</span> Round with a Gable
                      design
                    </li>
                    <li>
                      <span className="fw-bold">Usage/Application:</span> Used
                      to secure plastic or netting to GI pipes in polyhouses
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 5-6 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    <span className="fw-bold">Eco-Friendly:</span> Made from
                    recyclable galvanized iron.
                  </li>
                  <li>
                    <span className="fw-bold">Easy Installation:</span> Can be
                    quickly attached to pipes for securing plastic or nets.
                  </li>
                  <li>
                    <span className="fw-bold">
                      Durable & Weather-Resistant:
                    </span>{" "}
                    The galvanized coating ensures protection against rust and
                    environmental conditions.
                  </li>
                  <li>
                    <span className="fw-bold">Strong Grip:</span> Holds the
                    covering material firmly, ensuring stability during high
                    winds or heavy rain.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">Why Choose RGCPL’s Round Pipe Clamp?:</h5>
        <p className="text-justify">
          Trusted across the industry for long-lasting performance. Manufactured
          using high-quality GI material, ensuring superior strength and
          durability. Ideal for both small and large greenhouse structures.
        </p>
        <h5 className="mt-3">
          Key Benefits of Round Pipe Clamp (Gable Shaped Polyhouse Clamp):
        </h5>
        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Secure Fastening:</span> Ensures that
              plastic films or shade nets remain tightly attached to the
              structure, even during high winds.
            </li>
            <li>
              <span className="fw-bold"> Durability:</span> Lasts for up to 5
              years, offering excellent value for money.
            </li>
            <li>
              <span className="fw-bold"> Corrosion Resistant:</span> Galvanized
              coating ensures the clamp withstands outdoor conditions.
            </li>
            <li>
              <span className="fw-bold">Affordable and Reliable:</span> A
              cost-effective solution for securing greenhouse or polyhouse
              materials.
            </li>
          </ul>{" "}
        </p>

        <h5 className="mt-3">
          Importance of Round Pipe Clamp (Gable Shaped Polyhouse Clamp):
        </h5>
        <p className="text-justify">
          <ul>
            <li>
              Round pipe clamps are essential components in polyhouses and
              greenhouses.
            </li>
            <li>
              {" "}
              They help secure plastic films or shade nets to the GI pipes,
              ensuring that the structure remains stable, especially during
              extreme weather conditions.
            </li>
            <li>
              {" "}
              They protect the crops by tightly holding the cover material in
              place, safeguarding against wind and rain damage.
            </li>
          </ul>{" "}
        </p>

        <h5 className="mt-3">
          Applications of Round Pipe Clamp (Gable Shaped Polyhouse Clamp):
        </h5>
        <p className="text-justify">
          <ul>
            <li>
              Securing plastic films or shade nets to the framework of
              polyhouses and greenhouses.
            </li>
            <li>
              Providing stability to the structure during weather changes by
              ensuring that the covering stays intact.
            </li>
            <li>
              Commonly used in both commercial and small-scale greenhouses.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Round_Pipe_Clamp_Gable_Shaped_Polyhouse_Clamp;
