import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabPulseMultimedia from "../../Assets/Bluelab Pulse Multimedia.webp";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_Pulse_Multimedia = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 38,
      name: "Bluelab Pulse Multimedia EC/MC Meter",
      price: 25704,
      image: BluelabPulseMultimedia,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabPulseMultimedia}
                  alt="Bluelab pulse multimedia"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Bluelab Pulse Multimedia EC/MC Meter
            </h2>
            <p className="product-price">₹{25704}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>
                Instant Root Zone Measurements for Moisture, Conductivity (EC),
                and Temperature
              </h5>
              Achieve precise measurements of moisture, conductivity (EC), and
              temperature in the root zone with a single click. This innovative
              tool connects seamlessly to the Bluelab Pulse App, allowing you to
              view, analyze, and store all your measurement data conveniently.
              The app is available in multiple languages, including English,
              Spanish, Dutch, German, and Korean.
              <h5 className="mt-3">Key Features:</h5>
              <ul>
                <li>
                  One-click measurements for moisture, conductivity (EC), and
                  temperature directly in the root zone.
                </li>
                <li>
                  Connects to the Bluelab Pulse App for comprehensive data
                  management and analysis.
                </li>
                <li>
                  Equipped with robust 8” (200 mm) stainless steel probes for
                  deeper insights and reliable readings.
                </li>
                <li>
                  Features a built-in depth gauge to ensure consistent
                  measurements every time (replacement depth gauge and probe tip
                  cover available as spare parts).
                </li>
                <li>
                  Versatile use in soil, coco-coir, nutrient solutions, and
                  other media*.
                </li>
                <li>
                  <i>
                    *Note: Not suitable for use in media with large voids (e.g.,
                    Perlite) or those that can be permanently distorted (e.g.,
                    Rockwool).
                  </i>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_Pulse_Multimedia;
