import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import AutogrowEnviroSensor from "../../Assets/Autogrow EnviroSensor IntelliClimate CO2 Sensor.webp";
import "../../App.css"; // Import your CSS file for styles
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Autogrow_EnviroSensor_IntelliClimate_CO2_Sensor = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 6,
      name: "Autogrow EnviroSensor IntelliClimate CO2 Sensor",
      price: 74088,
      image: AutogrowEnviroSensor,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={AutogrowEnviroSensor}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Autogrow EnviroSensor IntelliClimate CO2 Sensor
            </h2>
            <p className="product-price">₹{74088}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>
                Monitor Climate Conditions with the Autogrow EnviroSensor with
                CO2
              </h5>
              The Autogrow EnviroSensor with CO2 is a high-performance tool
              designed to enhance climate control in your growing area. Whether
              you're using it as a replacement for the original IntelliClimate
              sensor or adding a second one, this sensor delivers precise
              measurements of critical factors like temperature, humidity, and
              CO2 levels. It automates climate regulation, creating optimal
              conditions for plant growth. Suitable for greenhouses and indoor
              farming, the sensor helps ensure your crops thrive in the best
              possible environment.
              <ul>
                <li>
                  Ideal for rooms larger than 20' x 20' to improve measurement
                  accuracy.
                </li>
                <li>
                  IntelliClimate averages data from multiple sensors to make
                  smarter climate control decisions.
                </li>
                <li>
                  Automates environmental adjustments, reducing the need for
                  manual interventions.
                </li>
                <li>
                  Ideal for growers seeking to boost crop health with accurate
                  environmental control.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Autogrow_EnviroSensor_IntelliClimate_CO2_Sensor;
