import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabGuardianMonitor from "../../Assets/Bluelab Guardian Monitor Inline Wi-Fi.webp";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_Guardian_Monitor_Inline_WiFi = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 9,
      name: "/Bluelab Guardian Monitor Inline Wi-Fi",
      price: 47628,
      image: BluelabGuardianMonitor,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabGuardianMonitor}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Bluelab Guardian Monitor Inline Wi-Fi
            </h2>
            <p className="product-price">₹{47628}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>Hands-Free Monitoring.</h5>
              With the Wi-Fi-enabled Bluelab Guardian Monitor Inline, you can
              continuously monitor pH, conductivity (EC), and temperature in
              your nutrient feed line. Receive instant alerts on your phone and
              take full control of your system remotely. No more manual
              checks—ensure your nutrient delivery is always working at peak
              efficiency to maintain healthier plants.
              <ul>
                <li>
                  <span className="fw-bold">Wi-Fi connectivity</span> (can be
                  disabled) allows for remote monitoring.
                </li>
                <li>
                  <span className="fw-bold">High/low alarms</span> for pH, EC,
                  and temperature ensure quick issue resolution.
                </li>
                <li>
                  <span className="fw-bold">Non-volatile memory</span> retains
                  settings even after power loss.
                </li>
                <li>
                  <span className="fw-bold">Plant-safe green LEDs</span> with
                  adjustable brightness for better visibility.
                </li>
                <li>
                  <span className="fw-bold">
                    2-meter measurement probe cables
                  </span>{" "}
                  provide flexibility in setup.
                </li>
                <li>
                  <span className="fw-bold">110-240 VAC</span> mains power with
                  interchangeable plugs for global compatibility (North America,
                  Europe, UK, AU/NZ).
                </li>
                <li>
                  <span className="fw-bold">Replaceable pH probe</span> rated up
                  to 7 bar with a BNC connection for easy maintenance.
                </li>
              </ul>
              <h5>App Integration with Edenic by Bluelab:</h5>
              <ul>
                <li>Instant alarm alerts directly to your phone.</li>
                <li>
                  Remote monitoring and control for hassle-free management.
                </li>
                <li>History reporting to support data-driven decisions.</li>
              </ul>
              The Bluelab Guardian Monitor Inline comes with fittings for 1"
              NPT, providing reliable inline measurement capability. Capable of
              withstanding up to 7 bar, it's the ideal solution for ensuring
              your inline dosing system runs smoothly.
              <h5 className="mt-2">
                Simplify Your Monitoring with Bluelab Guardian Inline Wi-Fi:
              </h5>
              Monitor key parameters 24/7 and save time with continuous tracking
              of pH, EC, and temperature. By keeping these crucial variables
              stable, you'll optimize nutrient uptake and grow healthier plants.
              The Bluelab Guardian Monitor Wi-Fi streamlines the process,
              allowing you to focus more on growing and less on troubleshooting.
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_Guardian_Monitor_Inline_WiFi;
