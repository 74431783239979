import { useEffect } from "react";

const TawkToChat = () => {
  useEffect(() => {
    // Initialize Tawk.to API
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    // Create a script element
    const script = document.createElement("script");
    script.src = "https://embed.tawk.to/66a8dd1532dca6db2cb76ef3/1i41pv7f3";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "anonymous");
    script.async = true;

    // Append the script to the body
    const existingScript = document.querySelector(
      `script[src="${script.src}"]`
    );
    if (!existingScript) {
      document.body.appendChild(script);
    }

    // Cleanup script when the component is unmounted
    return () => {
      const existingScript = document.querySelector(
        `script[src="${script.src}"]`
      );
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return null;
};

export default TawkToChat;
