import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabPeriPodM3 from "../../Assets/Bluelab PeriPod M3.webp";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_PeriPod_M3 = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 14,
      name: "Bluelab PeriPod M3",
      price: 86940,
      image: BluelabPeriPodM3,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabPeriPodM3}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab PeriPod M3</h2>
            <p className="product-price">₹{86940}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>
                Effortlessly Automate pH and Nutrient Levels in Growing
                Reservoirs
              </h5>
              Achieve precision in managing your nutrient and pH levels with our
              advanced dosing system tailored for efficiency.
              <ul className="mt-3">
                <li>
                  <span className="fw-bold">Accurate Dosing:</span> Utilize
                  peristaltic pumps that deliver consistent and precise dosing
                  of nutrients and pH solutions into your reservoirs.
                </li>
                <li>
                  <span className="fw-bold">Space-Saving Design:</span> The
                  ultra-compact unit can be conveniently positioned lower than
                  the injection point, making it ideal for space-constrained
                  environments.
                </li>
                <li>
                  <span className="fw-bold">Quiet Operation:</span> Designed
                  with a fan-cooled system, this unit operates quietly while
                  ensuring optimal performance.
                </li>
                <li>
                  <span className="fw-bold">Manual Dosing Capability:</span>{" "}
                  Easily prime the tubing with manual dosing directly from the
                  unit.
                </li>
                <li>
                  <span className="fw-bold">Efficient Flow Rate:</span> Achieve
                  a pump flow rate of 120 ml/min for quick and effective
                  nutrient delivery.
                </li>
                <li>
                  <span className="fw-bold">Extended Tubing Options:</span>{" "}
                  Includes 4 meters (13 feet) of tubing for dosing pH adjustment
                  solutions, made from high-strength, acid- and
                  alkaline-resistant materials.
                </li>
                <li>
                  <span className="fw-bold">Safe Nutrient Delivery:</span> Comes
                  with two lengths of 4 meters (13 feet) of food-grade tubing
                  for dosing nutrient stock solutions.
                </li>
                <li>
                  <span className="fw-bold">Prevent Algae Growth:</span> Opaque
                  nutrient tubing effectively prevents algae growth, ensuring a
                  healthier growing environment.
                </li>
                <li>
                  <span className="fw-bold">Easy Maintenance:</span> The system
                  features replaceable peristaltic pumps and tubing for
                  hassle-free upkeep.
                </li>
                <li>
                  <span className="fw-bold">Durable Construction:</span> With a
                  rugged wall-mounted design, this system promises long-lasting
                  efficiency in your setup.
                </li>
                <li>
                  <span className="fw-bold">Expandable System:</span> Link
                  multiple PeriPods to create a scalable multi-part nutrient
                  dosing system with the capacity for up to 12 pumps*.
                </li>
              </ul>
              <span style={{ fontStyle: "italic" }}>
              *Note: Only M series (M3 and M4) can be connected together. L3
              units can only chain with other L3 units.
              </span>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_PeriPod_M3;
