import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory8 from "../../Assets/accessory8.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const Pipe_Joiner_Connector_Kit_by_RGCPL = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 54,
      name: "Pipe Joiner/Connector Kit by RGCPL",
      price: 250,
      image: accessory8,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory8}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Pipe Joiner/Connector Kit by RGCPL</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Pipe Joiner/Connector Kit by RGCPL is designed to provide
                  robust and reliable connections between greenhouse frame
                  elements. Each kit includes a wrench and additional components
                  that allow for precise and easy assembly. The joiners are
                  essential for creating strong joints in greenhouse structures,
                  ensuring stability under stress or weather fluctuations.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> Galvanized Iron
                      (GI)
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> Black
                    </li>
                    <li>
                      <span className="fw-bold">Size:</span> Standard for pipe
                      diameters of 25mm to 50mm
                    </li>
                    <li>
                      <span className="fw-bold">Components:</span> Connector
                      wrench, fasteners, and joint heads
                    </li>

                    <li>
                      <span className="fw-bold">Usage/Application:</span>{" "}
                      Agriculture/Greenhouse frame setup
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 3-5 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>Eco-friendly</li>
                  <li>Easy to use</li>
                  <li>Precision-engineered for tight connections</li>
                  <li>Structure: GI (Galvanized Iron)</li>
                  <li>Built Type: Pipe connector assembly</li>
                  <li>Type: Greenhouse pipe joiners</li>
                  <li>Pattern: RGCPL</li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Boxed,
                      individually wrapped kits
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>

        <h5 className="mt-3">Why Choose RGCPL for Pipe Joiner Kits?</h5>
        <p className="text-justify">
          RGCPL offers high-quality pipe joiner kits designed for precision and
          ease of use. These kits allow for reliable and strong connections
          between greenhouse pipes, ensuring a secure structure. By sourcing
          directly from RGCPL's in-house facility, you get competitively priced,
          high-performance joiner kits designed to last.
        </p>

        <h5 className="mt-3">Importance of Pipe Joiner Kits:</h5>
        <p className="text-justify">
          This connector kit is essential for assembling greenhouse or poly
          house structures with speed and efficiency. It ensures that the pipes
          are tightly fastened together, maintaining the structural integrity of
          the entire framework.
        </p>
        <h5 className="mt-3">Key Benefits:</h5>

        <p className="text-justify">
          <ul>
            <li>
              <span className="fw-bold">Secure Connections:</span> Ensures tight
              and secure joining of pipes, preventing shifting or loosening over
              time.
            </li>
            <li>
              <span className="fw-bold">Durability:</span> Made from galvanized
              iron, offering resistance to rust and weather exposure.
            </li>
            <li>
              <span className="fw-bold">Easy to use:</span> Comes with all
              necessary tools, including a wrench, making assembly
              straightforward.
            </li>
            <li>
              <span className="fw-bold">Customizable:</span> Fits a variety of
              pipe diameters commonly used in greenhouse structures.
            </li>
          </ul>
        </p>
        <h5 className="mt-3">Applications of Pipe Joiner Kits:</h5>
        <p className="text-justify">
          <ul>
            <li>Joining pipes in polyhouse and greenhouse structures.</li>
            <li>Assembing pipe frameworks in agricultural buildings.</li>
            <li>
              Securing pipe joints in general construction projects.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Pipe_Joiner_Connector_Kit_by_RGCPL;
