import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import accessory33 from "../../Assets/accessory33.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab, Nav } from "react-bootstrap";
import "../../App.css";

const H_hook = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 79,
      name: "H-Hook",
      price: 4,
      image: accessory33,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={accessory33}
            alt="Poly House Gutter Plate"
            className="d-block w-100"
          />
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">H-Hook</h2>
            <p className="product-price">
              <a href="/Contact" className="btn btn-link">
                Contact Us for Price Details
              </a>
            </p>
            {/* <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button> */}

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-justify">
                  The Greenhouse H Hook is an essential accessory used for
                  hanging and securing elements within polyhouse and greenhouse
                  structures. Its robust design ensures that wires, nets, or
                  irrigation pipes are securely fastened, providing ease of use
                  and reliable support in both small and large greenhouse
                  setups.
                </p>
                <i>Note: Minimum Order Quantity: 1000 Units.</i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Section with Border */}
      <div className="row mt-4">
        <div className="col-12 border-tabs">
          <Tab.Container defaultActiveKey="Specifications">
            <Nav variant="tabs" className="mb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="Specifications" className="custom-tab">
                  Product Specifications
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Features" className="custom-tab">
                  Features
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="moreInfo" className="custom-tab">
                  Additional Information
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="Specifications">
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Material:</span> High-Density
                      Plastic
                    </li>
                    <li>
                      <span className="fw-bold">Color:</span> Black
                    </li>

                    <li>
                      <span className="fw-bold">Size:</span> 2 inches
                    </li>
                    <li>
                      <span className="fw-bold">Usage/Application:</span>{" "}
                      Hanging and securing wires, nets, and irrigation pipes in
                      greenhouse and polyhouse structures
                    </li>
                    <li>
                      <span className="fw-bold">Brand:</span> RGCPL
                    </li>
                    <li>
                      <span className="fw-bold">Durability:</span> 3-5 Years
                    </li>
                    <li>
                      <span className="fw-bold">Minimum Order Quantity:</span>{" "}
                      500 pieces
                    </li>
                  </ul>
                </p>
              </Tab.Pane>

              <Tab.Pane eventKey="Features">
                <h4>Features</h4>
                <ul>
                  <li>
                    <span className="fw-bold">Versatile Usage:</span> Can be
                    used to hang or secure a variety of elements in greenhouse
                    structures.
                  </li>
                  <li>
                    <span className="fw-bold">Easy to use:</span>
                    The hook design allows for simple attachment, making it
                    quick to install or adjust.
                  </li>
                </ul>
              </Tab.Pane>

              <Tab.Pane eventKey="moreInfo">
                <h4>Additional Information</h4>
                <p>
                  <ul>
                    <li>
                      <span className="fw-bold">Item Code:</span> RGCPL-0012
                    </li>
                    <li>
                      <span className="fw-bold">Delivery Time:</span> 7-8 Days
                    </li>
                    <li>
                      <span className="fw-bold">Port of Dispatch:</span>{" "}
                      Maharashtra, India
                    </li>
                    <li>
                      <span className="fw-bold">Production Capacity:</span> 1000
                      Pieces Per Day
                    </li>
                    <li>
                      <span className="fw-bold">Packaging Details:</span> Large
                      Bags
                    </li>
                    <li>
                      <span className="fw-bold">Payment Terms:</span> Advance
                      Payment
                    </li>
                    <li>
                      <span className="fw-bold">Payment Mode:</span> RTGS / NEFT
                      / IMPS
                    </li>
                  </ul>
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <h5 className="mt-3">Why choose Royal Green House for H-Hooks?:</h5>
        <p className="text-justify">
          Royal Green House provides high-quality Plastic and Metal Greenhouse H
          Hooks, designed to offer sturdy and reliable support for hanging or
          securing elements in greenhouses, polyhouses, and net houses. These
          hooks are essential for ensuring plant health and effective greenhouse
          management by securely hanging or attaching items like shade nets,
          wires, or irrigation pipes. With their durable and flexible design, H
          Hooks are perfect for long-term agricultural use, providing reliable
          support in various environmental conditions.
        </p>
        <h5 className="mt-3">Key Benefits of H-Hook:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Sturdy Support: Ensures secure attachment of critical components,
              preventing movement or dislocation.
            </li>
            <li>
              Rust-Resistant: Galvanized steel construction guarantees
              long-lasting durability in outdoor conditions.
            </li>
          </ul>
        </p>

        <h5 className="mt-3">Applications of H-Hook:</h5>
        <p className="text-justify">
          <ul>
            <li>
              Used for hanging shade nets, securing irrigation pipes, or
              fastening support wires in greenhouses and polyhouses.
            </li>
            <li>
              {" "}
              Provides additional support in managing greenhouse elements such
              as ventilation or shading systems.
            </li>
          </ul>
        </p>
      </div>

      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default H_hook;
